import posthog from "posthog-js"; // new
import React, { useState, useEffect } from "react";
export function CookiesBanner(props: any) {
  const [showBannerTimer, setShowBannerTimer] = useState(false); // new
  const [showBanner, setShowBanner] = useState(true); // new
  console.log("timer", showBannerTimer);

  useEffect(() => {
    // new
    setTimeout(() => {
      setShowBannerTimer(true);
    }, 5000);
  }, []);
  const acceptCookies = () => {
    posthog.opt_in_capturing(); // new
    setShowBanner(false); // new
  };

  const declineCookies = () => {
    posthog.opt_out_capturing(); // new
    setShowBanner(false); // new
  };

  return (
    <>
      {showBanner && showBannerTimer && (
        <section className="w-[90%] md:w-full  fixed z-[9999] max-w-md p-4 mx-auto bg-white border border-gray-200 transform  left-[50%] md:left-12 translate-x-[-50%] md:translate-x-0  bottom-16  rounded-2xl  shadow-lg ">
          <h2 className="font-semibold text-gray-800 ">🍪 Cookie Notice</h2>

          <p className="mt-4 text-sm text-gray-600 ">
            We use cookies to ensure that we give you the best experience on our
            website.{" "}
          </p>

          <div className="flex items-center justify-end mt-4 gap-x-4 shrink-0">
            <button
              type="button"
              onClick={declineCookies}
              className="text-xs bg-gray-100 font-medium rounded-lg hover:bg-gray-200 text-black px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              Decline
            </button>

            <button
              type="button"
              onClick={acceptCookies}
              className=" text-xs bg-[#302ECB] font-medium rounded-lg hover:bg-[#100e6d] text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              Accept
            </button>
          </div>
        </section>
      )}
    </>
  );
}
