import React from "react";
import devisionXLogo from "../../assets/Logo.png";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="overflow-hidden relative z-10 bg-indigo-50  border-t border-slate-400 pt-[1.5rem] pb-[1.5rem] h-[12rem] sm:h-[7.5rem]  flex items-center justify-center">
        <div className="container mx-auto">
          <div className="sm:-mx-4 h-full w-full  grid grid-cols-1 sm:grid-cols-2 gap-[2rem] md:gap-[8rem] lg:gap-[18rem]  ">
            <div className=" w-full px-4 flex  items-center justify-center  ">
              <div className=" grid text-center sm:text-start self-center ">
                <p className="mb-2 text-[14px] text-slate-600">
                  Copyright &copy; {year} Tuba.ai - All rights reserved
                </p>
                <a
                  href="https://devisionx.com/"
                  className=" text-[14px] text-slate-[600] flex items-start gap-3"
                >
                  <span className="self-center">Powered by</span>
                  <img src={devisionXLogo} className="h-[1.8rem]" alt="" />
                  {/* <span className="text-[#364fc7]  font-bold">DevisionX</span> */}
                </a>
              </div>
            </div>
            <div className=" w-full px-4 self-center">
              <div className="flex items-center justify-center gap-2">
                <div className=" flex items-center">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/devisionxco/?_rdc=1&_rdr"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-[#E5E5E5] text-dark hover:border-primary hover:bg-primary hover:text-[#302ECB] sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <svg
                      width="8"
                      height="16"
                      viewBox="0 0 8 16"
                      className="fill-current"
                    >
                      <path d="M7.43902 6.4H6.19918H5.75639V5.88387V4.28387V3.76774H6.19918H7.12906C7.3726 3.76774 7.57186 3.56129 7.57186 3.25161V0.516129C7.57186 0.232258 7.39474 0 7.12906 0H5.51285C3.76379 0 2.54609 1.44516 2.54609 3.5871V5.83226V6.34839H2.10329H0.597778C0.287819 6.34839 0 6.63226 0 7.04516V8.90323C0 9.26452 0.243539 9.6 0.597778 9.6H2.05902H2.50181V10.1161V15.3032C2.50181 15.6645 2.74535 16 3.09959 16H5.18075C5.31359 16 5.42429 15.9226 5.51285 15.8194C5.60141 15.7161 5.66783 15.5355 5.66783 15.3806V10.1419V9.62581H6.13276H7.12906C7.41688 9.62581 7.63828 9.41935 7.68256 9.10968V9.08387V9.05806L7.99252 7.27742C8.01466 7.09677 7.99252 6.89032 7.85968 6.68387C7.8154 6.55484 7.61614 6.42581 7.43902 6.4Z" />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    href="https://twitter.com/devisionx"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-[#E5E5E5] text-dark hover:border-primary hover:bg-primary hover:text-[#302ECB] sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <svg
                      width="16"
                      height="12"
                      viewBox="0 0 16 12"
                      className="fill-current"
                    >
                      <path d="M14.2194 2.06654L15.2 0.939335C15.4839 0.634051 15.5613 0.399217 15.5871 0.2818C14.8129 0.704501 14.0903 0.845401 13.6258 0.845401H13.4452L13.3419 0.751468C12.7226 0.258317 11.9484 0 11.1226 0C9.31613 0 7.89677 1.36204 7.89677 2.93542C7.89677 3.02935 7.89677 3.17025 7.92258 3.26419L8 3.73386L7.45806 3.71037C4.15484 3.61644 1.44516 1.03327 1.00645 0.587084C0.283871 1.76125 0.696774 2.88845 1.13548 3.59296L2.0129 4.90802L0.619355 4.20352C0.645161 5.18982 1.05806 5.96477 1.85806 6.52838L2.55484 6.99804L1.85806 7.25636C2.29677 8.45401 3.27742 8.94716 4 9.13503L4.95484 9.36986L4.05161 9.93346C2.60645 10.8728 0.8 10.8024 0 10.7319C1.62581 11.7652 3.56129 12 4.90323 12C5.90968 12 6.65806 11.9061 6.83871 11.8356C14.0645 10.2857 14.4 4.41487 14.4 3.2407V3.07632L14.5548 2.98239C15.4323 2.23092 15.7935 1.8317 16 1.59687C15.9226 1.62035 15.8194 1.66732 15.7161 1.6908L14.2194 2.06654Z" />
                    </svg>
                  </a>

                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/devisionx"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-[#E5E5E5] text-dark hover:border-primary hover:bg-primary hover:text-[#302ECB] sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      className="fill-current"
                    >
                      <path d="M13.0214 0H1.02084C0.453707 0 0 0.451613 0 1.01613V12.9839C0 13.5258 0.453707 14 1.02084 14H12.976C13.5432 14 13.9969 13.5484 13.9969 12.9839V0.993548C14.0422 0.451613 13.5885 0 13.0214 0ZM4.15142 11.9H2.08705V5.23871H4.15142V11.9ZM3.10789 4.3129C2.42733 4.3129 1.90557 3.77097 1.90557 3.11613C1.90557 2.46129 2.45002 1.91935 3.10789 1.91935C3.76577 1.91935 4.31022 2.46129 4.31022 3.11613C4.31022 3.77097 3.81114 4.3129 3.10789 4.3129ZM11.9779 11.9H9.9135V8.67097C9.9135 7.90323 9.89082 6.8871 8.82461 6.8871C7.73571 6.8871 7.57691 7.74516 7.57691 8.60323V11.9H5.51254V5.23871H7.53154V6.16452H7.55423C7.84914 5.62258 8.50701 5.08065 9.52785 5.08065C11.6376 5.08065 12.0232 6.43548 12.0232 8.2871V11.9H11.9779Z" />
                    </svg>
                  </a>

                  <a
                    target="_blank"
                    href="https://discord.gg/gDSZr6N5rC"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-[#E5E5E5] text-dark hover:border-primary hover:bg-primary hover:text-[#302ECB] sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      className="fill-current"
                    >
                      <path d="M11.8593 2.01625C10.9531 1.4685 9.98409 1.07042 8.97102 0.84375C8.84661 1.13433 8.70125 1.52517 8.60104 1.83609C7.52412 1.62687 6.45712 1.62687 5.40001 1.83609C5.29981 1.52517 5.15116 1.13433 5.02563 0.84375C4.01147 1.07042 3.04136 1.46996 2.13511 2.01915C0.307208 5.58744 -0.188307 9.06709 0.0594514 12.4973C1.27181 13.6669 2.44674 14.3774 3.60184 14.8423C3.88704 14.3352 4.14139 13.7962 4.36052 13.2282C3.94319 13.0233 3.54348 12.7705 3.16579 12.477C3.26599 12.3811 3.364 12.2809 3.45869 12.1777C5.76228 13.5696 8.26519 13.5696 10.5413 12.1777C10.6371 12.2809 10.7351 12.3811 10.8342 12.477C10.4554 12.7719 10.0546 13.0247 9.63722 13.2296C9.85635 13.7962 10.1096 14.3367 10.3959 14.8437C11.5521 14.3788 12.7281 13.6684 13.9405 12.4973C14.2312 8.52081 13.4439 5.07311 11.8593 2.01625ZM4.67435 10.3878C3.98284 10.3878 3.41574 9.5538 3.41574 8.53824C3.41574 7.52267 3.97073 6.68727 4.67435 6.68727C5.378 6.68727 5.94508 7.52121 5.93297 8.53824C5.93406 9.5538 5.378 10.3878 4.67435 10.3878ZM9.3256 10.3878C8.63408 10.3878 8.06699 9.5538 8.06699 8.53824C8.06699 7.52267 8.62196 6.68727 9.3256 6.68727C10.0292 6.68727 10.5963 7.52121 10.5842 8.53824C10.5842 9.5538 10.0292 10.3878 9.3256 10.3878Z" />
                    </svg>
                  </a>

                  <a
                    target="_blank"
                    href="https://medium.com/devisionx"
                    className="mr-3 flex h-8 w-8 items-center justify-center rounded-full border border-[#E5E5E5] text-dark hover:border-primary hover:bg-primary hover:text-[#302ECB] sm:mr-4 lg:mr-3 xl:mr-4"
                  >
                    <svg
                      width="14"
                      height="18"
                      viewBox="0 0 14 14"
                      className="fill-current"
                    >
                      <path d="M3.94849 0.875C6.12918 0.875 7.89684 4.00918 7.89684 7.87512C7.89684 11.7411 6.12905 14.875 3.94849 14.875C1.76793 14.875 0 11.7411 0 7.87512C0 4.00918 1.76779 0.875 3.94849 0.875ZM10.2539 1.28505C11.3443 1.28505 12.2282 4.23523 12.2282 7.87512H12.2283C12.2283 11.5141 11.3444 14.4652 10.2541 14.4652C9.16373 14.4652 8.27983 11.5141 8.27983 7.87512C8.27983 4.23617 9.16359 1.28505 10.2539 1.28505ZM13.3057 1.97146C13.689 1.97146 14 4.61457 14 7.87512C14 11.1347 13.6892 13.7788 13.3057 13.7788C12.9221 13.7788 12.6114 11.1354 12.6114 7.87512C12.6114 4.61481 12.9223 1.97146 13.3057 1.97146Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
