import React, { useEffect, useState } from "react";
import {
  createStyles,
  Container,
  Text,
  Button,
  Group,
  rem,
  Paper,
} from "@mantine/core";
import ipadMockup from "../assets/Ipad Mockup.png";
import ipadMocku2 from "../../assets/ipadMockUp2.png";
import ipadMocku3 from "../../assets/IpadMockup3.png";
import ipadMocku4 from "../../assets/IpadMockup4.png";
import ScrollReveal from "scrollreveal";
import Iframe from "react-iframe";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    boxSizing: "border-box",
    // backgroundColor:
    //   theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },

  inner: {
    position: "relative",
    paddingTop: rem(200),
    paddingBottom: rem(120),

    [theme.fn.smallerThan("sm")]: {
      paddingBottom: rem(80),
      paddingTop: rem(80),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(62),
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(42),
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: rem(24),

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(18),
    },
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: rem(54),
    paddingLeft: rem(38),
    paddingRight: rem(38),

    [theme.fn.smallerThan("sm")]: {
      height: rem(54),
      paddingLeft: rem(18),
      paddingRight: rem(18),
      flex: 1,
    },
  },
}));

export const HeroTitle = React.forwardRef((props: any, ref: any) => {
  const { classes } = useStyles();
  // Seamless AI Vision With Zero-Code

  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".revealHero", {
      origin: "left",
      distance: "50px",
      duration: 600,
      delay: 550,
    });

    // sr.reveal(".revealHeroImg", {
    //   origin: "top",
    //   distance: "50px",
    //   duration: 400,
    //   delay: 250,
    // });
  }, []);

  return (
    <>
      <section
        className="relative container h-[30rem] sm:h-[33rem] md:h-[35rem] bg-indigo-50  md:rounded-2xl mx-auto py-[3rem] mb-[10rem] md:mb-[15rem]"
        id="/"
        ref={ref}
      >
        <div className={classes.wrapper}>
          <Container size={800} className={`${classes.inner} py-0 text-center`}>
            <div className="revealHero">
              <h1
                className={`${classes.title} text-[34px] md:text-[48px] lg:text-[62px]`}
              >
                Seamless{" "}
                <Text
                  component="span"
                  variant="gradient"
                  gradient={{ from: "indigo.9", to: "indigo.3" }}
                  inherit
                >
                  AI Vision
                </Text>{" "}
                For Everyone
              </h1>

              <Text className={classes.description} color="dimmed">
                One platform to label, train, deploy and monitor your AI Vision
                apps
              </Text>

              <Group
                className={`${classes.controls} w-[90%] md:w-[100%] mx-auto my-[2.4rem] justify-center`}
              >
                <Button
                  size="md"
                  className={`${classes.control} heroBtnGradient text-[16px] lg:text-[20px] px-[1rem]  md:px-[2rem] `}
                  variant="gradient"
                  onClick={() => {
                    window.open("https://calendly.com/samarhamdy/30min");
                  }}
                >
                  Request Demo
                </Button>

                <Button
                  component="a"
                  size="md"
                  variant="default"
                  className={`${classes.control} text-[16px] lg:text-[18px] px-[1rem]  md:px-[2rem] `}
                  onClick={() => {
                    window.open("https://app.tuba.ai/sign-up");
                  }}
                >
                  Try Now
                </Button>
              </Group>
            </div>
          </Container>
          <div className="absolute left-[50%] translate-x-[-50%] bottom-[-110%] sm:bottom-[-200%] md:bottom-[-150%] xl:bottom-[-190%] 2xl:bottom-[-220%]  w-[97%] md:w-[85%] lg:w-[75%]  ">
            <div className=" fadeInFromDownToUpNoOpacity relative ">
            <iframe
                className="w-[95%] h-[250px]  md:h-[350px] lg:w-[90%] lg:h-[450px] mx-auto border-[5px] border-[#302ecb] rounded-xl"
                src="https://www.youtube.com/embed/eTnMlrVcKUA"              
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});
