import React, { useEffect } from "react";
import BlurryShapes from "../animated-shapes/BlurryShapes";
import ScrollReveal from "scrollreveal";
import mobileScreen1 from "../../assets/mobile1.png";
import mobileScreen2 from "../../assets/mobile2.png";
import mobileScreen3 from "../../assets/mobile3.svg";
import mobileScreen4 from "../../assets/mobile4.png";
import mobileScreen5 from "../../assets/mobile5.png";
import mobileScreen6 from "../../assets/mobileview.png";
import mobileView1 from "../../assets/MobileView1.png";
import mobileView2 from "../../assets/MobileView2.png";
import heroImg from "../../assets/hero-image.png";
import about from "../../assets/about-pic.png";
import about2 from "../../assets/about-picsvg.svg";

const AboutTuba = () => {
  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".aboutDesc", {
      origin: "left",
      duration: 500,
      distance: "300px",
      delay: 200,
      easing: "ease-in-out",
      // wanna make fade in effect
      opacity: 0,
      scale: 0.5,
    });

    sr.reveal(".left-side", {
      origin: "right",
      duration: 500,
      distance: "300px",
      delay: 200,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <section
        className="overflow-hidden container mt-[4rem] mb:mb-[4rem] md:mb-[10rem] mx-auto py-4  "
        id="about"
      >
        <div className="container mx-auto">
          <div className="relative gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <BlurryShapes className="absolute top-[4rem] left-[1rem]" />

            <div className="flex flex-col text-center md:text-start items-center md:items-start justify-center  md:justify-start">
              <span className="aboutDesc  mb-2 block text-lg text-[14px] font-bold text-[#302ECB]">
                What Is Tuba?
              </span>
              <h2 className="aboutDesc w-[95%] md:w-full mb-[1.2rem] text-[1.4rem] sm:text-[1.6rem]  md:text-[2rem] font-extrabold text-dark ">
                Build Your Computer vision Apps with No AI Experience.
              </h2>
              <p className="aboutDesc mb-4 text-[16px] md:text-[18px] text-slate-500 font-medium leading-8  ">
                Tuba is a No-code/Low Code platform to label, train your data
                and deploy your AI-vision applications faster, easier through a
                set of tools tailored for a wide range of cases.
              </p>
              <p className="aboutDesc  mb-8 text-[16px] md:text-[18px] text-slate-500 font-medium leading-8 ">
                Build your app directly on tuba, integrate the models inside
                your application, or dive deeper and use the SDKs for building
                custom apps, all powered with state-of-the-art technology and
                production-ready standards.
              </p>

              <div className="w-full  md:w-[25%] lg:w-[35%]  mb-8 md:mb-0">
                <button
                  className="aboutDesc w-full bg-[#302ECB] flex items-center justify-center text-center rounded-lg  py-3 px-7  text-[16px]  font-bold transition-all duration-300 text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                  onClick={() => {
                    window.open("https://app.tuba.ai/sign-up");
                  }}
                >
                  Try Now
                </button>
              </div>
            </div>
            <div className="left-side grid grid-cols-1 gap-4 mt-8">
              <div className="w-full h-[300px] md:h-[400px] overflow-hidden cursor-pointer transition-all duration-150 rounded-lg">
                <img
                  className="w-full h-full rounded-lg hover:scale-105 transition-all duration-150"
                  // src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
                  alt="office content 1"
                  src={about2}
                />
              </div>
              {/* <div className="w-full h-[325px] md:h-[450px] overflow-hidden cursor-pointer transition-all duration-150 rounded-lg">
                <img
                  className="mt-5 w-full h-full rounded-lg hover:scale-105 transition-all duration-150"
                  src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
                  alt="office content 2"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <section
        classNameName="overflow-hidden container my-[2rem] mx-auto"
        id="about"
      >
        <div classNameName="overflow-hidden lg:overflow-visible pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
          <div classNameName="relative -mx-4 flex flex-wrap items-center justify-between">
            <BlurryShapes classNameName="absolute top-[-2rem] right-[7rem]" />

            <div classNameName="left-side w-full px-[1.8rem] md:px-0 lg:px-4 lg:w-6/12">
              <div classNameName="-mx-3 flex items-center sm:-mx-4 ">
                <div classNameName="w-full px-3 sm:px-4 xl:w-1/2">
                  <div classNameName="h-[28rem] overflow-hidden border mb-4 border-[#c7c6ff] rounded-2xl   flex items-center justify-center text-center md:text-start">
                    <img
                      src=""
                      alt=""
                      classNameName="w-[80%] h-full md:w-full flex items-center justify-center rounded-2xl cursor-pointer hover:scale-105 transition-all duration-300"
                    />
                  </div>
                  <div classNameName="h-[28rem] overflow-hidden border mb-4 border-[#c7c6ff] rounded-2xl  flex items-center justify-center text-center md:text-start">
                    <img
                      src={mobileView1}
                      alt=""
                      classNameName="w-[80%] h-full md:w-full flex items-center justify-center rounded-2xl cursor-pointer hover:scale-105 transition-all duration-300"
                    />
                  </div>
                </div>
                <div classNameName="h-[28rem]  rounded-2xl w-full px-3 sm:px-4 xl:w-1/2">
                  <div classNameName="h-[28rem]  overflow-hidden rounded-2xl border mb-4 border-[#c7c6ff] relative z-10 my-4">
                    <img
                      src={mobileScreen6}
                      alt=""
                      classNameName="w-[80%] top-[-4rem] absolute md:w-full rounded-2xl cursor-pointer hover:scale-105 transition-all duration-300"
                    />
                    <span classNameName="absolute -right-7 -bottom-7 z-[-1]">
                      <svg
                        width="134"
                        height="106"
                        viewBox="0 0 134 106"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="1.66667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 1.66667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 16.3333 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 31 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 45.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3334"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 60.3334 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 88.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 117.667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 74.6667 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 103 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="104"
                          r="1.66667"
                          transform="rotate(-90 132 104)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 31 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="89.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 89.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 103 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="89.3338"
                          r="1.66667"
                          transform="rotate(-90 132 89.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="74.6673"
                          r="1.66667"
                          transform="rotate(-90 1.66667 74.6673)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 16.3333 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 31 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 31 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 45.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="31.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 31.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 60.3333 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 60.3333 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 88.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 88.6667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 117.667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 117.667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 74.6667 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 74.6667 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 103 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 103 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="74.6668"
                          r="1.66667"
                          transform="rotate(-90 132 74.6668)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="30.9998"
                          r="1.66667"
                          transform="rotate(-90 132 30.9998)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 1.66667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 16.3333 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 31 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 31 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 45.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 60.3333 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 60.3333 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 88.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 88.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 117.667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 117.667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 74.6667 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 74.6667 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 103 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 103 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="60.0003"
                          r="1.66667"
                          transform="rotate(-90 132 60.0003)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="16.3333"
                          r="1.66667"
                          transform="rotate(-90 132 16.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 1.66667 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="1.66667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 1.66667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 16.3333 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="16.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 16.3333 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 31 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="31"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 31 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="45.3333"
                          r="1.66667"
                          transform="rotate(-90 45.6667 45.3333)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="45.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 45.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 60.3333 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="60.3333"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 60.3333 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 88.6667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="88.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 88.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 117.667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="117.667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 117.667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 74.6667 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="74.6667"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 74.6667 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 103 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="103"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 103 1.66683)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="45.3338"
                          r="1.66667"
                          transform="rotate(-90 132 45.3338)"
                          fill="#3056D3"
                        />
                        <circle
                          cx="132"
                          cy="1.66683"
                          r="1.66667"
                          transform="rotate(-90 132 1.66683)"
                          fill="#3056D3"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div classNameName="w-full px-[1.8rem] md:px-0 lg:px-4  lg:w-1/2 xl:w-5/12">
              <div classNameName="mt-10 lg:mt-0 flex flex-col text-center md:text-start items-center md:items-start justify-center  md:justify-start">
                <span classNameName="aboutDesc mb-2 block text-lg text-[14px] font-bold text-[#302ECB]">
                  What Is Tuba?
                </span>
                <h2 classNameName="aboutDesc mb-8 text-3xl font-bold text-dark sm:text-4xl">
                  No Coding, No AI Experience needed.
                </h2>
                <p classNameName="aboutDesc mb-8 text-base text-body-color">
                  Tuba is a No-code/Low Code platform to label, train your data
                  and deploy your AI-vision applications faster, easier in a
                  modular process.
                </p>
                <p classNameName="aboutDesc mb-12 text-base text-body-color">
                  No Coding, No AI Experience needed, you can build Machine
                  Learning Model (ML Model) easily like using apps on the
                  smartphone
                </p>
                <button
                  classNameName="aboutDesc bg-[#302ECB] flex items-center justify-center text-center rounded-lg  py-3 px-7  text-[16px]  font-bold transition-all duration-300 text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                  onClick={() => {
                    window.open("https://app.tuba.ai/sign-up");
                  }}
                >
                  Try Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AboutTuba;
