import React, { useEffect, useState, useRef } from "react";
import { Title } from "@mantine/core";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./contact.module.css";
// import svgBg from '../../assets/bg'
import ScrollReveal from "scrollreveal";
import { useForm } from "@mantine/form";
import {
  NumberInput,
  TextInput,
  Select,
  Button,
  Box,
  Textarea,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { Dialog, Group, Text } from "@mantine/core";
import emailjs from "@emailjs/browser";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";

const SELECT_DATA = [
  { value: "Standard", label: "Standard" },
  { value: "Plus", label: "Plus" },
  { value: "Customized", label: "Customized" },
];

export function Contact() {
  const [opened, { toggle, close }] = useDisclosure(false);

  // Tracker
  const gaEventTracker = useAnalyticsEventTracker("Contact us");

  // states for the form inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [org, setOrg] = useState("");
  const [email, setEmail] = useState("");
  const [tubaPlans, setTubaPlans] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState("");

  const formRef = useRef<any>();
  function captchaonChange(value: any) {
    console.log("Captcha value:", value);
  }
  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      org: "",
      email: "",
      tubaPlans: "",
      phoneNumber: "",
      message: "",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      firstName: (value) =>
        value.length < 2 ? "First name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Last name must have at least 2 letters" : null,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      jobTitle: (value) =>
        value.length < 1 ? "Job Title field is required" : null,
      org: (value) =>
        value.length < 1 ? "Organization field is required" : null,

      tubaPlans: (value) => (value ? null : "Please select a plan"),

      // Now set the form values to the states
    },
  });

  const formSubmitionHandler = (event: any) => {
    event.preventDefault();
    // first: Validiate all the form
    form.validate();

    if (form.isValid()) {
      setFirstName(form.values.firstName);
      setLastName(form.values.lastName);
      setEmail(form.values.email);
      setJobTitle(form.values.jobTitle);
      setOrg(form.values.org);
      setTubaPlans(form.values.tubaPlans);
      setPhoneNumber(form.values.phoneNumber);
      setMessage(form.values.message);

      // wanna toggle the dialog box to show user feedback
      toggle();

      // send email using emailjs
      emailjs
        .sendForm(
          "service_1lyeog6",
          "template_zvy12gg",
          formRef.current,
          "oDnm9eNqYq3jIrZuS"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log("email sent successfully");
          },
          (error) => {
            console.log(error.text);
          }
        );

      // tracker for the event
      gaEventTracker("Contact us form submition");
      // reset all the form inputs
      form.reset();
    }
  };

  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".contactLeftHeader", {
      origin: "top",
      duration: 700,
      interval: 200,
      delay: 950,
    });
    sr.reveal(".contact-item", {
      origin: "left",
      distance: "50px",
      duration: 200,
      delay: 1000,
      interval: 250,
    });

    sr.reveal(".svgItem1", {
      origin: "right",
      duration: 400,
      delay: 1000,
    });
    sr.reveal(".svgItem2", {
      origin: "right",
      duration: 400,
      delay: 700,
    });
    sr.reveal(".svgItem3", {
      origin: "bottom",
      duration: 400,
      delay: 850,
    });

    sr.reveal(".rightSide", {
      origin: "top",
      duration: 400,
      delay: 800,
    });

    sr.reveal(".formWrapper", {
      origin: "top",
      duration: 500,
      delay: 900,
    });
    sr.reveal(".inputItem", {
      origin: "left",
      distance: "50px",
      duration: 200,
      delay: 1300,
      interval: 250,
    });
  }, []);

  return (
    <>
      <div className="overflow-hidden" id="contact">
        <section className="relative z-10 overflow-hidden  py-[6rem] px-[2rem] md:px-[4rem] bg-indigo-50  ">
          <div className="container mx-auto">
            {/* <div className="-mx-4 flex flex-wrap lg:justify-between"> */}

            <div className="-mx-4 flex flex-col gap-[2rem] md:gap-[5rem] items-center justify-center ">
              <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
                <div className="leftSide mb-12 max-w-[570px] lg:mb-0">
                  <div className="contactLeftHeader">
                    <h4 className="mb-1 text-center  block text-lg text-[18px] font-bold text-indigo-700">
                      Contact Us
                    </h4>
                    <Title className={`${styles.faqTitle} text-center`}>
                      We'd love to hear from you
                    </Title>
                    <p className="mb-9 text-base leading-relaxed text-center text-slate-700">
                      If you would like to contact us for any reason, please use
                      the form below and we will get back to you as soon as
                      possible or email us at{" "}
                      <a
                        href="mailto:support@tuba.ai"
                        className="underline-none text-[#302ECB] text-[16px] font-bold"
                      >
                        support@tuba.ai
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-[2rem] md:mt-0 px-4 lg:w-1/2 xl:w-7/12">
                <div className="rightSide relative rounded-lg bg-white p-8 shadow-lg sm:p-12">
                  <form
                    ref={formRef}
                    className="formWrapper"
                    onSubmit={form.onSubmit(console.log)}
                  >
                    <div className="w-full flex-col sm:flex-row flex gap-4">
                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        {/* <input
                        type="text"
                        placeholder="First Name"
                        {...form.getInputProps("name")}
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                        <TextInput
                          placeholder="First Name"
                          {...form.getInputProps("firstName")}
                          size="md"
                          name="firstName"
                          fz={16}
                        />
                      </div>
                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        <TextInput
                          name="lastName"
                          placeholder="Last Name"
                          {...form.getInputProps("lastName")}
                          size="md"
                          fz={16}
                        />
                        {/* <input
                        type="text"
                        placeholder="Last Name"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                      </div>
                    </div>
                    <div className="w-full flex-col sm:flex-row flex gap-4">
                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        {/* <input
                        type="text"
                        placeholder="Job Title"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                        <TextInput
                          name="jobTitle"
                          placeholder="Job Title"
                          {...form.getInputProps("jobTitle")}
                          size="md"
                          fz={16}
                        />
                      </div>
                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        {/* <input
                        type="text"
                        placeholder="Organization"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                        <TextInput
                          name="org"
                          placeholder="Organization"
                          {...form.getInputProps("org")}
                          size="md"
                          fz={16}
                        />
                      </div>
                    </div>
                    <div className="w-full flex-col sm:flex-row flex gap-4">
                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        {/* <input
                        type="email"
                        placeholder="Your Email"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                        <TextInput
                          withAsterisk
                          placeholder="your@email.com"
                          {...form.getInputProps("email")}
                          size="md"
                          name="email"
                          fz={16}
                        />
                      </div>

                      <div
                        className={`inputItem w-full ${styles.contactInput} mb-6`}
                      >
                        {/* <input
                        type="text"
                        placeholder="Your Phone"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> */}
                        <TextInput
                          name="phoneNumber"
                          placeholder="Your Phone"
                          {...form.getInputProps("phoneNumber")}
                          size="md"
                          fz={16}
                        />
                      </div>
                    </div>

                    <div
                      className={`inputItem w-full ${styles.contactInput} mb-6`}
                    >
                      {/* <select
                        {...form.getInputProps("tubaPlans")}
                        name="tubaPlans"
                        className={`${styles.formSelectInput} bg-tra  border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none`}
                      >
                        <option selected disabled hidden>
                          Select your preferred Tuba plan
                        </option>
                        <option value="Standart">Standard</option>
                        <option value="Plus">Plus</option>
                        <option value="Customized">Customized</option>
                      </select> */}
                      <Select
                        placeholder="Select your preferred Tuba plan"
                        data={SELECT_DATA}
                        {...form.getInputProps("tubaPlans")}
                        name="tubaPlans"
                        size="md"
                        fz={16}
                        dropdownPosition="bottom"
                        transitionProps={{
                          transition: "pop-top-left",
                          duration: 80,
                          timingFunction: "ease",
                        }}
                        styles={(theme) => ({
                          item: {
                            // applies styles to selected item
                            "&[data-selected]": {
                              "&, &:hover": {
                                backgroundColor: "#302ecb",
                                color: "#fff",
                              },
                            },
                          },
                        })}
                      />
                    </div>

                    {/* <div className={`inputItem ${styles.contactInput} mb-6`}>
                       <input
                        type="text"
                        placeholder="Your Phone"
                        className="border-[f0f0f0] w-full rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      /> 
                      <TextInput
                        name="phoneNumber"
                        placeholder="Your Phone"
                        {...form.getInputProps("phoneNumber")}
                        size="md"
                        fz={16}
                      />
                   
                    </div> */}
                    <div className={`inputItem ${styles.contactInput} mb-6`}>
                      {/* <textarea
                        placeholder="Your Message"
                        className="border-[f0f0f0] h-[10rem]  w-full resize-none rounded border py-3 px-[14px] text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none"
                      ></textarea> */}
                      <Textarea
                        name="message"
                        placeholder="Your Message"
                        {...form.getInputProps("message")}
                        size="md"
                        fz={16}
                      />
                    </div>
                    <div className={`inputItem ${styles.contactInput} mb-6`}>
                      <div className="flex items-center justify-center w-full ">
                        <ReCAPTCHA
                          sitekey="6Ldc994jAAAAAN54Rfk2k3-tgLuUxNUevsc6JZn-"
                          onChange={captchaonChange}
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        // wanna console.log the form data
                        onClick={formSubmitionHandler}
                        className="w-full rounded border border-primary bg-[#302ecb] p-3 text-white transition hover:bg-opacity-90"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  {/* Dialog after submition of the form */}
                  {/* <Group position="center">
                    <Button onClick={toggle}>Toggle dialog</Button>
                  </Group> */}

                  <Dialog
                    className="border-l-[6px] border-green-600 z-[9999] "
                    opened={opened}
                    withCloseButton
                    onClose={close}
                    size="lg"
                    radius="md"
                    position={{ bottom: "1rem", right: "1rem" }}
                  >
                    <div className="flex items-start justify-center gap-3 z-[99999]">
                      <div className="self-center">
                        <svg
                          className="w-[3.5rem] h-[3.5rem]"
                          viewBox="0 0 47 47"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="23.5"
                            cy="23.5"
                            r="23.5"
                            fill="#76B900"
                            fill-opacity="0.15"
                          />
                          <path
                            d="M23.5 35.25C29.9625 35.25 35.25 29.9625 35.25 23.5C35.25 17.0375 29.9625 11.75 23.5 11.75C17.0375 11.75 11.75 17.0375 11.75 23.5C11.75 29.9625 17.0375 35.25 23.5 35.25Z"
                            stroke="#76B900"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M18.5039 23.5001L21.8292 26.8253L28.4914 20.1748"
                            stroke="#76B900"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col ">
                        <Text
                          size="sm"
                          mb="xs"
                          weight={600}
                          className="mb-[0.3rem]"
                        >
                          Thank You!
                        </Text>

                        <Group align="flex-end">
                          <Text
                            size="sm"
                            mb="xs"
                            weight={400}
                            className="mb-[0]"
                          >
                            Your subimission has been received.
                          </Text>
                        </Group>
                      </div>
                    </div>
                  </Dialog>
                  <div>
                    <span className="svgItem1 absolute -top-10 -right-9 z-[-2]">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                          fill="#3056D3"
                        />
                      </svg>
                    </span>
                    <span className="svgItem2 absolute -right-10 top-[90px] z-[-2]">
                      <svg
                        width="34"
                        height="134"
                        viewBox="0 0 34 134"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="31.9993"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 31.9993 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 31.9993 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 31.9993 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 31.9993 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 31.9993 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 31.9993 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 31.9993 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 31.9993 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 31.9993 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 31.9993 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 17.3333 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 17.3333 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 17.3333 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 17.3333 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 17.3333 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 17.3333 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 17.3333 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 17.3333 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 17.3333 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 17.3333 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 2.66536 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 2.66536 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 2.66536 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 2.66536 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 2.66536 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 2.66536 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 2.66536 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 2.66536 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 2.66536 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 2.66536 1.66665)"
                          fill="#13C296"
                        />
                      </svg>
                    </span>
                    <span className="svgItem3 absolute -left-7 -bottom-7 z-[-2]">
                      <svg
                        width="107"
                        height="134"
                        viewBox="0 0 107 134"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="104.999"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 104.999 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 104.999 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 104.999 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 104.999 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 104.999 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 104.999 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 104.999 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 104.999 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 104.999 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="104.999"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 104.999 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 90.3333 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 90.3333 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 90.3333 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 90.3333 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 90.3333 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 90.3333 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 90.3333 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 90.3333 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 90.3333 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="90.3333"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 90.3333 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 75.6654 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 31.9993 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 75.6654 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 31.9993 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 75.6654 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 31.9993 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 75.6654 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 31.9993 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 75.6654 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 31.9993 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 75.6654 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 31.9993 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 75.6654 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 31.9993 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 75.6654 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 31.9993 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 75.6654 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 31.9993 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="75.6654"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 75.6654 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="31.9993"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 31.9993 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 60.9993 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 17.3333 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 60.9993 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 17.3333 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 60.9993 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 17.3333 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 60.9993 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 17.3333 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 60.9993 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 17.3333 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 60.9993 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 17.3333 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 60.9993 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 17.3333 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 60.9993 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 17.3333 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 60.9993 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 17.3333 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="60.9993"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 60.9993 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="17.3333"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 17.3333 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 46.3333 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="132"
                          r="1.66667"
                          transform="rotate(180 2.66536 132)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 46.3333 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="117.333"
                          r="1.66667"
                          transform="rotate(180 2.66536 117.333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 46.3333 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="102.667"
                          r="1.66667"
                          transform="rotate(180 2.66536 102.667)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 46.3333 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="88"
                          r="1.66667"
                          transform="rotate(180 2.66536 88)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 46.3333 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="73.3333"
                          r="1.66667"
                          transform="rotate(180 2.66536 73.3333)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 46.3333 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="45"
                          r="1.66667"
                          transform="rotate(180 2.66536 45)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 46.3333 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="16"
                          r="1.66667"
                          transform="rotate(180 2.66536 16)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 46.3333 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="59"
                          r="1.66667"
                          transform="rotate(180 2.66536 59)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 46.3333 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="30.6666"
                          r="1.66667"
                          transform="rotate(180 2.66536 30.6666)"
                          fill="#13C296"
                        />
                        <circle
                          cx="46.3333"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 46.3333 1.66665)"
                          fill="#13C296"
                        />
                        <circle
                          cx="2.66536"
                          cy="1.66665"
                          r="1.66667"
                          transform="rotate(180 2.66536 1.66665)"
                          fill="#13C296"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
