import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Text } from "@mantine/core";
import ScrollReveal from "scrollreveal";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
// import { MantineLogo } from '@mantine/ds';
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
const HEADER_HEIGHT = rem(85);

const content = {
  animate: {
    transition: { staggerChildren: 0.1, delayChildren: 3.8 },
  },
};

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",
    height: "100vh",
    width: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
    // padding:"1rem 0.5rem",

    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  header: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    padding: "15px 0",
    marginBottom: "1rem",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("md")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "0.9rem 1.2rem",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: "17px",
    fontWeight: 500,
    transition: "color 500ms ease, background-color 500ms ease",

    "&:hover": {
      color: theme.colors.indigo[9],
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.indigo[0],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  //   background-color: #4f46e524;
  //   color: #6366f1;
  // }
  linkActive: {
    "&, &:hover": {
      color: "#302ecb",
    },
  },
  active: {
    color: "#302ecb !important",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.indigo[0],
  },
  linkCTA: {
    backgroundColor: "indigo.9",
    color: "fff",
    borderRadius: "8px",

    "&:hover": {
      color: "#fff",
      backgroundColor: "indigo.7",
    },
  },
}));

interface HeaderResponsiveProps {
  links: {
    to: string;
    offset: number;
    duration: number;
    label: string;
  }[];
}

export function HeaderNavigation({
  links,
  className,
}: HeaderResponsiveProps & { className?: string }) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].to);
  const [linkTo, setLinkTo] = useState(links[0].to);
  const { classes, cx } = useStyles();

  const gaEventTracker = useAnalyticsEventTracker("Navigation");

  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".navItem", {
      origin: "right",
      delay: 50,
      interval: 150,
      distance: "50px",
      duration: 200,
    });
    sr.reveal(".web-Logo", {
      origin: "left",
      delay: 350,
      distance: "50px",
      duration: 500,
    });
  }, []);

  const items = links.map((link) => (
    // if the link is CTA then render the button with specif classname
    <Link
      to={link.to}
      key={link.label}
      smooth={true}
      offset={link.offset}
      activeClass={classes.active}
      spy={true}
      duration={link.duration}
      className={`${cx(classes.link, {})} ${
        link.to === "request-demo"
          ? "bg-[#302ECB] text-[17px] text-white rounded-md border border-transparent transition-all duration-200 ml-[0.5rem]   hover:bg-[#3b5bdb] hover:text-white"
          : ""
      } ${
        link.to === "login"
          ? "bg-white text-[17px]  text-[#302ECB] border border-[#9e9cff] rounded-md transition-all duration-200 ml-[1.4rem]   hover:bg-[#302ECB] hover:text-white hover:border-[#302ECB] "
          : ""
      } 
      } navItem cursor-pointer
      
       `}
      onClick={() => {
        // tracker for the event
        gaEventTracker(link.label);

        setActive(link.to);
        if (link.to === "login") {
          window.open("https://app.tuba.ai/");
        }
        if (link.to === "request-demo") {
          // replace the current url with calendy link
          window.open("https://calendly.com/samarhamdy/30min");
        }
        console.log("link.to", link.to);
        // scroll to the target element
        close();
      }}
    >
      {link.label}
    </Link>
  ));
  // const updateActiveLink = () => {
  //   alert("hello");
  // };

  // create button with gradient of indigo-9 and blue-5
  // const gradient = `linear-gradient(45deg, ${theme.colors.indigo[9]} 0%, ${theme.colors.blue[5]} 100%)`;

  return (
    // <section className="container mx-auto">
    <Header
      height={HEADER_HEIGHT}
      className={`${classes.root} ${className}  mb-0 md:mb-[1rem] `}
    >
      <Container
        className={`${classes.header} container mx-auto  w-[90%]   md:first-letter:w-full`}
      >
        <Text
          className="text-[24px] md:text-[38px]  text-[#364fc7] web-Logo"
          weight={800}
        >
          TUBA.AI
        </Text>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
    // </section>
  );
}
