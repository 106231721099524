import React, { useEffect } from "react";
import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import {
  IconFileAnalytics,
  IconStack2,
  IconSTurnRight,
} from "@tabler/icons-react";
import ScrollReveal from "scrollreveal";
import { Icon } from "@iconify/react";

const mockdata = [
  {
    title: "Tuba Label",
    description:
      "Labeling hundreds of thousands of images can be tiring, but using our labeling solution, you can label them in a timely manner, and prepare the dataset for image classification, object detection and image segmentation tasks, and if you don't have the manpower to train it, you can use our automatic labeling solution to help you speed up the processand prepare the dataset for image classification, object detection and image segmentation tasks, and if you don't have the manpower to train it, you can use our automatic labeling solution to help you speed up the process",
    icon: IconFileAnalytics,
  },
  {
    title: "Tuba Train",
    description:
      "If you have your data ready, you can import directly and configure your model for training, if you don't know what to configure, you can let our engine decide for you. Tuba's AutoML engine searchs intelligently across thousands of possible models, and chooses the most fitting one for your case, by combining automated hyperparameter tuning and neural architecture search. ",
    icon: IconSTurnRight,
  },
  {
    title: "Tuba Deploy",
    description:
      "Whether you have your model trained by tuba, or you trained it on your own using your favorite framework, you can use our SDK or web interface to deploy your model to AWS, GCP or Azure in few clicks, either as an API endpoint, or a visual dashboard.",
    icon: IconStack2,
  },
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 700,
    margin: "auto",

    // "&::after": {
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#302ECB",
    //   width: rem(45),
    //   height: rem(2),
    //   marginTop: theme.spacing.sm,
    //   marginLeft: "auto",
    //   marginRight: "auto",
    // },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",

    "&:hover": {
      backgroundColor: theme.colors.indigo[9],
      color: "#fff",
      //   style the cardTitle
      "cardTitle::after": {
        backgroundColor: "#fff",
      },
    },
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",

      backgroundColor: "#302ECB",
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export function Features() {
  const { classes, theme } = useStyles();

  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".feauture-card", {
      origin: "left",
      delay: 300,
      interval: 200,
      distance: "50px",
      duration: 500,
    });
    sr.reveal(".headReveal", {
      origin: "left",
      delay: 300,
      distance: "50px",
      duration: 500,
    });
  }, []);
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={`${classes.card} group feauture-card `}
      padding="lg"
    >
      <feature.icon
        size={rem(50)}
        stroke={2}
        className="text-[#302ECB] w-[2.2rem] group-hover:text-white"
      />
      <Text
        className={`${classes.cardTitle}  text-start text-[18px] md:text-[22px] tracking-tight font-bold text-gray-900  group-hover:text-white `}
        mt="md"
      >
        {feature.title}
      </Text>
      <Text
        className="text-[14px] md:text-[15px] text-justify  group-hover:text-gray-100"
        c="dimmed"
        mt="sm"
      >
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <>
      <section className="overflow-hidden w-full relative" id="solutions">
        <svg
          className="absolute bottom-[-60rem] left-[-30rem] md:left-0 opacity-[25%] lg:opacity-[15%]"
          width="2634"
          height="2634"
          viewBox="0 0 2634 2634"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_768_3122)">
            <circle cx="1317" cy="1317" r="317" fill="#302ECB" />
          </g>
          <defs>
            <filter
              id="filter0_f_768_3122"
              x="0"
              y="0"
              width="2634"
              height="2634"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="500"
                result="effect1_foregroundBlur_768_3122"
              />
            </filter>
          </defs>
        </svg>
        <div className="container my-[5rem] mx-auto">
          <Container size="lg" py="xl">
            <div className="headReveal">
              <Group position="center">
                <Badge className="bg-[#302ECB]" variant="filled" size="lg">
                  TUBA AS NO CODE
                </Badge>
              </Group>

              <Title order={2} className={classes.title} ta="center" mt="sm">
                No-code AI vision apps builder
              </Title>

              <Text
                c="dimmed"
                className={`${classes.description}   w-[85%] md:w-[75%] lg:w-[45%]  `}
                ta="center"
                mt="md"
              >
                Label your dataset, train, deploy and monitor your models
                without writing a single line of code.{" "}
                <span
                  className="font-bold text-[#302ECB] transition-all duration-300 pb-[3px] cursor-pointer hover:border-b-2 hover:border-[#302ECB]"
                  onClick={() => {
                    window.open("https://app.tuba.ai/sign-up");
                  }}
                >
                  Try Now
                </span>
              </Text>

              {/* <div className="w-full flex items-center justify-center mt-6">
                <button
                  className="w-[12rem] aboutDesc bg-[#302ECB] flex items-center justify-center text-center rounded-lg  py-3 px-7  text-[16px]  font-bold transition-all duration-300 text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
                  onClick={() => {
                    window.open("https://app.tuba.ai/sign-up");
                  }}
                >
                  Try Now
                </button>
              </div> */}
            </div>

            <SimpleGrid
              cols={3}
              spacing="xl"
              mt={50}
              breakpoints={[
                { maxWidth: "sm", cols: 1 },
                { maxWidth: "md", cols: 1 },
                { maxWidth: "lg", cols: 3 },
              ]}
            >
              {features}
            </SimpleGrid>
          </Container>
        </div>
      </section>
    </>
  );
}
