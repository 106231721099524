import React from "react";
import styles from './shapes.module.css'

const BlurryShapes = (props:any) => {
  return (
    <>
    <section className={props.className}>
      <div className={styles.container}>
        <div className={styles.shapeOne}></div>
        <div className={styles.shapeTwo}></div>
        <div className={styles.shapeThree}></div>
      </div>
      </section>
    </>
  );
};

export default BlurryShapes;
