import { Badge, Group, Text } from "@mantine/core";
import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";

export function Features2() {
  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".feauture-card", {
      origin: "left",
      delay: 300,
      interval: 200,
      distance: "50px",
      duration: 500,
    });
    sr.reveal(".headReveal", {
      origin: "top",
      delay: 300,
      distance: "50px",
      duration: 500,
    });
  }, []);
  return (
    <>
      <div className="bg-white py-24 sm:pb-32">
        <div className="mx-auto max-w-7xl px-4 ">
          <div className="mx-auto max-w-2xl lg:text-center">
            <Group position="center">
              <Badge
                className="bg-[#302ECB] mb-[10px"
                variant="filled"
                size="lg"
              >
                TUBA AS LOW CODE
              </Badge>
            </Group>
            <p className="headReveal text-center my-[1rem] w-[95%] md:w-full mb-[0.5rem] text-[1.6rem]  md:text-[2rem] font-extrabold text-dark">
              Tuba As A Low Code AI Vision Apps Builder
            </p>
            <Text
              c="dimmed"
              className={` w-100 md:w-[75%] lg:w-[95%] mx-auto  `}
              ta="center"
              mt="md"
            >
              Tuba is a low code AI vision apps builder that allows you to label
              your dataset, train, deploy and monitor your models without
              writing a single line of code.{" "}
              <span
                className="font-bold text-[#302ECB] transition-all duration-300 pb-[3px] cursor-pointer hover:border-b-2 hover:border-[#302ECB]"
                onClick={() => {
                  window.open("https://app.tuba.ai/sign-up");
                }}
              >
                Try Now
              </span>
            </Text>
            {/* <p className="headReveal text-center mt-2 text-lg leading-8 text-gray-600">
              Tuba is a low code AI vision apps builder that allows you to label
              your dataset, train, deploy and monitor your models without
              writing a single line of code.
            </p> */}
          </div>
          <div className="mx-auto mt-16  w-full  sm:mt-20 lg:mt-24 ">
            <dl className="grid grid-cols-1 px-6 gap-x-8 gap-y-10  md:grid-cols-2 lg:gap-y-16">
              <div className="feauture-card relative pl-16">
                <dt className="text-[18px] font-bold leading-6 text-black mb-3">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#302ECB]">
                    <svg
                      className="h-6 w-6 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 17V11L7 13"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 11L11 13"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  Automatic Image Labeling SDK
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The Automatic Image Labeling SDK is a powerful SDK designed to
                  provide developers with an efficient and user-friendly
                  solution for automating the process of labeling images.
                </dd>
              </div>
              <div className="feauture-card relative pl-16">
                <dt className="text-[18px] font-bold leading-6 text-black mb-3">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#302ECB]">
                    <svg
                      className="h-6 w-6 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.66 10.44L20.68 14.62C19.84 18.23 18.18 19.69 15.06 19.39C14.56 19.35 14.02 19.26 13.44 19.12L11.76 18.72C7.59 17.73 6.3 15.67 7.28 11.49L8.26 7.30001C8.46 6.45001 8.7 5.71001 9 5.10001C10.17 2.68001 12.16 2.03001 15.5 2.82001L17.17 3.21001C21.36 4.19001 22.64 6.26001 21.66 10.44Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.06 19.3901C14.44 19.8101 13.66 20.1601 12.71 20.4701L11.13 20.9901C7.15998 22.2701 5.06997 21.2001 3.77997 17.2301L2.49997 13.2801C1.21997 9.3101 2.27997 7.2101 6.24997 5.9301L7.82997 5.4101C8.23997 5.2801 8.62998 5.1701 8.99998 5.1001C8.69998 5.7101 8.45997 6.4501 8.25997 7.3001L7.27997 11.4901C6.29997 15.6701 7.58998 17.7301 11.76 18.7201L13.44 19.1201C14.02 19.2601 14.56 19.3501 15.06 19.3901Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.64 8.52979L17.49 9.75979"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.66 12.3999L14.56 13.1399"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  Classification Models Training SDK
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The Classification Models Training SDK is a comprehensive
                  software development kit designed to empower software
                  engineers in building and training classification models for
                  various applications.
                </dd>
              </div>
              <div className="feauture-card relative pl-16">
                <dt className="text-[18px]  font-bold leading-6 text-black mb-3">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#302ECB]">
                    <svg
                      className="h-6 w-6 text-white"
                      width="71"
                      height="101"
                      viewBox="0 0 71 101"
                      fill="none"
                      stroke-width="1.5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_35_242)">
                        <path
                          d="M10.5059 83.365C13.9576 83.365 16.7559 80.5668 16.7559 77.115C16.7559 73.6632 13.9576 70.865 10.5059 70.865C7.05408 70.865 4.25586 73.6632 4.25586 77.115C4.25586 80.5668 7.05408 83.365 10.5059 83.365Z"
                          fill="white"
                        />
                        <path
                          d="M10.5059 85.449C4.75187 85.449 0.0888672 90.111 0.0888672 95.865V100.032H20.9219V95.865C20.9219 90.111 16.2589 85.449 10.5059 85.449Z"
                          fill="white"
                        />
                        <path
                          d="M35.5059 83.365C38.9576 83.365 41.7559 80.5668 41.7559 77.115C41.7559 73.6632 38.9576 70.865 35.5059 70.865C32.0541 70.865 29.2559 73.6632 29.2559 77.115C29.2559 80.5668 32.0541 83.365 35.5059 83.365Z"
                          fill="white"
                        />
                        <path
                          d="M35.5059 85.449C29.7519 85.449 25.0889 90.111 25.0889 95.865V100.032H45.9219V95.865C45.9219 90.111 41.2589 85.449 35.5059 85.449Z"
                          fill="white"
                        />
                        <path
                          d="M60.5059 83.365C63.9576 83.365 66.7559 80.5668 66.7559 77.115C66.7559 73.6632 63.9576 70.865 60.5059 70.865C57.0541 70.865 54.2559 73.6632 54.2559 77.115C54.2559 80.5668 57.0541 83.365 60.5059 83.365Z"
                          fill="white"
                        />
                        <path
                          d="M60.5059 85.449C54.7519 85.449 50.0889 90.111 50.0889 95.865V100.032H70.9209V95.865C70.9219 90.111 66.2589 85.449 60.5059 85.449Z"
                          fill="white"
                        />
                        <path
                          d="M58.4219 66.699C59.0609 66.699 59.6779 66.406 60.0779 65.881C60.6189 65.173 60.6489 64.201 60.1559 63.46C60.1229 63.409 57.2269 59.017 56.5029 54.199H60.5059C63.9519 54.199 66.7559 51.395 66.7559 47.949V6.28198C66.7559 2.83598 63.9519 0.0319824 60.5059 0.0319824H10.5059C7.05986 0.0319824 4.25586 2.83598 4.25586 6.28198V47.949C4.25586 51.396 7.05986 54.199 10.5059 54.199H14.5069C13.7799 58.999 10.8869 63.413 10.8539 63.463C10.3629 64.203 10.3939 65.174 10.9359 65.881C11.3389 66.406 11.9549 66.699 12.5899 66.699C12.8099 66.699 13.0309 66.664 13.2479 66.59C13.7739 66.416 25.2449 62.49 26.9529 54.199H29.8369L33.5289 65.275C33.8119 66.125 34.6069 66.699 35.5049 66.699C36.4029 66.699 37.1979 66.125 37.4819 65.275L41.1729 54.199H44.0479C45.7529 62.494 57.2359 66.417 57.7629 66.59C57.9799 66.664 58.2029 66.699 58.4219 66.699ZM48.0059 52.115C48.0059 50.964 47.0719 50.032 45.9219 50.032H39.6719C38.7749 50.032 37.9809 50.606 37.6959 51.457L35.5059 58.027L33.3159 51.457C33.0249 50.584 32.2119 50.041 31.3399 50.042V50.032H25.0889C23.9389 50.032 23.0059 50.964 23.0059 52.115C23.0059 55.449 20.1479 58.189 17.1919 60.074C18.0859 57.756 18.8399 54.955 18.8399 52.115C18.8399 50.964 17.9069 50.032 16.7569 50.032H10.5069C9.35786 50.032 8.42386 49.099 8.42386 47.949V6.28198C8.42386 5.13198 9.35886 4.19898 10.5069 4.19898H60.5069C61.6549 4.19898 62.5909 5.13198 62.5909 6.28198V47.949C62.5909 49.099 61.6549 50.032 60.5069 50.032H54.2569C53.1069 50.032 52.1729 50.964 52.1729 52.115C52.1729 54.947 52.9249 57.738 53.8139 60.054C50.9569 58.256 48.0059 55.563 48.0059 52.115Z"
                          fill="white"
                        />
                        <path
                          d="M35.5059 16.699C30.9039 16.699 27.1729 20.43 27.1729 25.032C27.1729 28.108 28.7819 29.899 30.2129 32.386C30.7459 33.314 31.3399 35.448 31.3399 36.49C31.3399 37.065 31.8059 37.532 32.3819 37.532H38.6319C39.2069 37.532 39.6729 37.065 39.6729 36.49C39.6729 35.448 40.2679 33.313 40.8009 32.386C42.2319 29.899 43.8409 28.108 43.8409 25.032C43.8389 20.43 40.1069 16.699 35.5059 16.699Z"
                          fill="white"
                        />
                        <path
                          d="M38.6309 39.615H32.3809C31.8059 39.615 31.3389 40.081 31.3389 40.657C31.3389 41.233 31.8049 41.699 32.3809 41.699H38.6309C39.2059 41.699 39.6719 41.233 39.6719 40.657C39.6719 40.081 39.2059 39.615 38.6309 39.615Z"
                          fill="white"
                        />
                        <path
                          d="M37.0679 43.782H33.9429C33.3679 43.782 32.9009 44.249 32.9009 44.824C32.9009 45.399 33.3669 45.866 33.9429 45.866H37.0679C37.6429 45.866 38.1089 45.399 38.1089 44.824C38.1089 44.249 37.6429 43.782 37.0679 43.782Z"
                          fill="white"
                        />
                        <path
                          d="M35.5059 14.615C36.0809 14.615 36.5479 14.148 36.5479 13.573V9.40699C36.5479 8.83199 36.0819 8.36499 35.5059 8.36499C34.9299 8.36499 34.4639 8.83199 34.4639 9.40699V13.574C34.4639 14.149 34.9299 14.615 35.5059 14.615Z"
                          fill="white"
                        />
                        <path
                          d="M24.0469 23.99H19.8799C19.3049 23.99 18.8379 24.456 18.8379 25.032C18.8379 25.608 19.3039 26.074 19.8799 26.074H24.0469C24.6219 26.074 25.0889 25.607 25.0889 25.032C25.0889 24.457 24.6219 23.99 24.0469 23.99Z"
                          fill="white"
                        />
                        <path
                          d="M27.7301 15.783L24.7841 12.837C24.3771 12.43 23.7181 12.43 23.3111 12.837C22.9041 13.244 22.9041 13.903 23.3111 14.31L26.2571 17.256C26.4611 17.46 26.7281 17.561 26.9941 17.561C27.2601 17.561 27.5271 17.459 27.7301 17.256C28.1361 16.849 28.1361 16.19 27.7301 15.783Z"
                          fill="white"
                        />
                        <path
                          d="M47.7011 12.837C47.2941 12.43 46.6351 12.43 46.2271 12.837L43.2811 15.783C42.8751 16.19 42.8751 16.849 43.2811 17.256C43.4851 17.46 43.7521 17.561 44.0171 17.561C44.2841 17.561 44.5501 17.459 44.7541 17.256L47.7001 14.31C48.1071 13.903 48.1071 13.244 47.7011 12.837Z"
                          fill="white"
                        />
                        <path
                          d="M51.1309 23.99H46.9649C46.3889 23.99 45.9219 24.456 45.9219 25.032C45.9219 25.608 46.3889 26.074 46.9649 26.074H51.1309C51.7059 26.074 52.1719 25.608 52.1719 25.032C52.1719 24.456 51.7059 23.99 51.1309 23.99Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_35_242">
                          <rect
                            width="70.833"
                            height="100"
                            fill="white"
                            transform="translate(0.0888672 0.0319824)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  Object Detection & Segmentation Training SDK
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The Object Detection & Segmentation Training SDK is a powerful
                  software development kit that empowers software engineers to
                  build and train models for object detection and segmentation
                  tasks.
                </dd>
              </div>
              <div className="feauture-card relative pl-16">
                <dt className="text-[18px] font-bold leading-6 text-black mb-3">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#302ECB]">
                    <svg
                      className="h-6 w-6 text-white"
                      width="82"
                      height="105"
                      viewBox="0 0 82 105"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        d="M20.953 44.3226C20.953 31.331 25.3751 18.7241 33.4948 8.57573C34.3885 7.45989 35.5216 6.5592 36.8103 5.94027C38.099 5.32134 39.5104 5 40.94 5C42.3696 5 43.781 5.32134 45.0697 5.94027C46.3584 6.5592 47.4915 7.45989 48.3852 8.57573C56.5032 18.7217 60.9263 31.3286 60.927 44.3226V58.1236C60.927 63.8199 60.927 66.6681 59.7978 68.8367C58.8506 70.657 57.3659 72.1417 55.5455 73.0889C53.3769 74.2182 50.5288 74.2182 44.8325 74.2182H37.0475C31.3512 74.2182 28.5031 74.2182 26.3345 73.0889C24.5141 72.1417 23.0294 70.657 22.0822 68.8367C20.953 66.6681 20.953 63.8199 20.953 58.1236V44.3226Z"
                        stroke="white"
                        stroke-width="9.99352"
                      />
                      <path
                        d="M45.9368 29.2473C45.9368 30.5726 45.4103 31.8435 44.4732 32.7806C43.5362 33.7176 42.2652 34.2441 40.94 34.2441C39.6148 34.2441 38.3438 33.7176 37.4068 32.7806C36.4697 31.8435 35.9432 30.5726 35.9432 29.2473C35.9432 27.9221 36.4697 26.6512 37.4068 25.7141C38.3438 24.777 39.6148 24.2506 40.94 24.2506C42.2652 24.2506 43.5362 24.777 44.4732 25.7141C45.4103 26.6512 45.9368 27.9221 45.9368 29.2473Z"
                        fill="white"
                      />
                      <path
                        d="M60.927 36.7425L65.1943 43.1433C74.3134 56.8244 78.8754 63.66 76.0472 68.9416C73.2241 74.2182 65.0044 74.2182 48.5651 74.2182H33.3149C16.8756 74.2182 8.65593 74.2182 5.83276 68.9416C3.0046 63.66 7.56664 56.8244 16.6857 43.1433L20.953 36.7425"
                        stroke="white"
                        stroke-width="9.99352"
                      />
                      <path
                        d="M40.94 89.2085V99.202M55.9303 89.2085V94.2052M25.9497 89.2085V94.2052"
                        stroke="white"
                        stroke-width="9.99352"
                        stroke-linecap="round"
                      />
                    </svg>
                  </div>
                  Model Deployment SDK
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The Model Deployment SDK is a comprehensive software
                  development kit that empowers software engineers to deploy
                  machine learning models efficiently and seamlessly.
                </dd>
              </div>
              <div className="md:col-span-2 feauture-card relative pl-16">
                <dt className="text-[18px] font-bold leading-6 text-black mb-3">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#302ECB]">
                    <svg
                      className="h-6 w-6 text-white"
                      viewBox="0 0 20 16"
                      fill="none"
                      strokeWidth="1.5"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8H7C7.26522 8 7.51957 7.89464 7.70711 7.70711C7.89464 7.51957 8 7.26522 8 7V1C8 0.734784 7.89464 0.48043 7.70711 0.292893C7.51957 0.105357 7.26522 0 7 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8ZM2 2H6V6H2V2ZM11 4H19C19.2652 4 19.5196 3.89464 19.7071 3.70711C19.8946 3.51957 20 3.26522 20 3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2H11C10.7348 2 10.4804 2.10536 10.2929 2.29289C10.1054 2.48043 10 2.73478 10 3C10 3.26522 10.1054 3.51957 10.2929 3.70711C10.4804 3.89464 10.7348 4 11 4ZM11 14H1C0.734784 14 0.48043 14.1054 0.292893 14.2929C0.105357 14.4804 0 14.7348 0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H11C11.2652 16 11.5196 15.8946 11.7071 15.7071C11.8946 15.5196 12 15.2652 12 15C12 14.7348 11.8946 14.4804 11.7071 14.2929C11.5196 14.1054 11.2652 14 11 14ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 6H11C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7C10 7.26522 10.1054 7.51957 10.2929 7.70711C10.4804 7.89464 10.7348 8 11 8H19C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6Z"
                        fill="white"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  Job Manager SDK
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  The Machine Learning Jobs Manager SDK is a comprehensive
                  software development kit designed to assist software engineers
                  in managing and orchestrating machine learning jobs
                  efficiently. This SDK provides a range of tools, libraries,
                  and APIs that enable developers to create, schedule, monitor,
                  and manage machine learning tasks and workflows. With this
                  SDK, software engineers can streamline the execution and
                  management of machine learning jobs, ensuring optimal resource
                  allocation, scalability, and performance.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
