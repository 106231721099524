import React, { useEffect } from "react";
import {
  Container,
  Title,
  Accordion,
  createStyles,
  rem,
  Badge,
} from "@mantine/core";

import styles from "./faq.module.css";
import BlurryShapes from "../animated-shapes/BlurryShapes";
import ScrollReveal from "scrollreveal";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    minHeight: 650,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export function FAQ() {
  const { classes } = useStyles();

  useEffect(() => {
    const sr = ScrollReveal({
      // configuration options
    });

    sr.reveal(".faqHeader", {
      origin: "top",
      distance: "50px",
      duration: 400,
      delay: 350,
    });
    sr.reveal(".faqItem", {
      origin: "left",
      distance: "50px",
      duration: 300,
      delay: 200,
      interval: 200,
    });
  }, []);
  return (
    <>
      <section className="overflow-hidden relative container mx-auto mt-[97rem] sm:mt-[40rem] lg:mt-[18rem] xl:mt-[15rem] ]">
        <Container size="sm" className={classes.wrapper}>
          <div className="faqHeader">
            <div className="flex items-center justify-center mt-[4rem]">
              <Badge
                className="bg-[#302ECB] uppercase text-center  "
                variant="filled"
                size="lg"
              >
                Frequently Asked Questions
              </Badge>
            </div>

            <Title
              align="center"
              className={`${classes.title} ${styles.faqTitle}`}
            >
              You ask? We <span className={styles.answerTitle}>answer!</span>
            </Title>
          </div>
          <BlurryShapes className="absolute top-[10rem] right-[6rem]" />
          <BlurryShapes className="absolute bottom-[-7rem] left-[1.5rem]" />

          <Accordion
            className="relative z-10 bg-white border border-slate-50 shadow rounded-lg py-[3rem] px-[2.2rem]"
            variant="separated"
          >
            {/* Q1 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q1"
            >
              <Accordion.Control className={styles.accordionTitle}>
                Do I need AI Experience to use Tuba ?
              </Accordion.Control>
              <Accordion.Panel>
                Tuba is designed to make Computer vision easier for both
                non-developers and developers too. You can use Tuba as No-code
                tool and enjoy its very easy interface to build your machine
                learning models. Or using it as a low code and choose your
                suitable SDK.
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q2 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q2"
            >
              <Accordion.Control className={styles.accordionTitle}>
                What is the preferred image size ?
              </Accordion.Control>
              <Accordion.Panel>
                We recommend that image size should not exceed 1024*1024 and not
                less than 64*64.
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q3 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q3"
            >
              <Accordion.Control className={styles.accordionTitle}>
                What are the supported types of image to use?
              </Accordion.Control>
              <Accordion.Panel>
                Tuba supported image types are: [JPG, PNG, JPEG]
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q4 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q4"
            >
              <Accordion.Control className={styles.accordionTitle}>
                What is the minimum required images per label to get better
                accuracy?
              </Accordion.Control>
              <Accordion.Panel>
                We recommend to add more images per class with minimum 30 images
                per label, The more, the better!
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q5 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q5"
            >
              <Accordion.Control className={styles.accordionTitle}>
                Can I upload datasets from google drive?
              </Accordion.Control>
              <Accordion.Panel>
                Yes, You can upload datasets from different resources such as
                You local PC or Google Drive.
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q6 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q6"
            >
              <Accordion.Control className={styles.accordionTitle}>
                What are the types of model format supported by Tuba?
              </Accordion.Control>
              <Accordion.Panel>
                Tuba support different model formats in calssification
                including: Tflite, Keras “h5”,TensorFlow "pb", Onnx model. And
                in Detection & Segmentation, It supports : pytorch models.
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q7 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q7"
            >
              <Accordion.Control className={styles.accordionTitle}>
                Can I deploy the models to edge devices?
              </Accordion.Control>
              <Accordion.Panel>
                Yes, You can download the model based on your choices in the
                training configuration. Select TFLite Model type in training
                configuration and then you are able to download the model and
                use it on edge device such as Nvidia Jetson or Pleora AIGW or
                OpenVINO .
              </Accordion.Panel>
            </Accordion.Item>
            {/* Q8 */}
            <Accordion.Item
              className={`${classes.item} ${styles.accordionItem} faqItem`}
              value="q8"
            >
              <Accordion.Control className={styles.accordionTitle}>
                Can I deploy the model on Mobile App?
              </Accordion.Control>
              <Accordion.Panel>
                Yes, You can download the model based on your choices in the
                training configuration. Select TFlite Model type in training
                configuration and then you are able to download the model and
                use it on your Software pipeline.
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </>
  );
}
