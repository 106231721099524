import React from "react";
import PLAccLogo from "../../assets/PL_Accel_Ext_member 1.svg";
import vision from "../../assets/Visionpier22.svg";
const PartOfLogos = () => {
  return (
    <>
      <section className="relative overflow-hidden w-full bg-[#dbe4ff] py-16 lg:py-[40px]">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between gap-[1rem] md:gap-[2rem]">
            <h2 className="text-[28px] md:text-[32px] text-center font-extrabold leading-[2rem] md:leading-6  text-black">
              Tuba is now part of these eco systems
            </h2>

            <div className="w-[90%] mx-auto md:w-auto grid grid-cols-2 gap-x-[1.6rem] items-center mt-[2rem] md:mt-0 ">
              {/* <svg
                className="w-[8rem] h-[8rem] md:w-[15rem] md:h-[10rem]"
                viewBox="0 0 143 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M107.651 5.57548C110.858 5.33061 111.817 6.65806 110.53 9.55778C107.644 10.816 106.204 9.91332 106.211 6.84981C106.658 6.34954 107.138 5.92477 107.651 5.57548Z"
                  fill="#060606"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M47.8261 7.16754C48.4746 7.11589 49.1144 7.16897 49.7456 7.32683C50.3288 8.87062 49.7955 9.40158 48.146 8.91975C47.463 8.42413 47.3565 7.84004 47.8261 7.16754Z"
                  fill="#181818"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M61.9016 7.16797C62.5415 7.16797 63.1813 7.16797 63.8211 7.16797C64.1436 8.81419 63.5572 9.34517 62.0616 8.76089C61.9036 8.24029 61.8505 7.70931 61.9016 7.16797Z"
                  fill="#040404"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M96.7713 26.2841C96.7521 26.1111 96.6456 26.0049 96.4514 25.9655C96.1325 28.0788 96.0259 30.2027 96.1315 32.3372C94.7453 32.3372 93.3588 32.3372 91.9727 32.3372C91.9727 25.6469 91.9727 18.9567 91.9727 12.2664C93.1646 12.1636 94.3378 12.2698 95.4917 12.585C95.6257 12.9584 95.8388 13.277 96.1315 13.5407C102.167 10.5493 105.473 12.4608 106.049 19.2752C105.982 22.1147 104.916 24.5041 102.85 26.4434C100.777 27.5522 98.7512 27.4991 96.7713 26.2841Z"
                  fill="#040404"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M124.285 20.8671C121.406 20.8671 118.526 20.8671 115.647 20.8671C115.571 21.2784 115.678 21.597 115.967 21.8228C116.216 22.655 116.749 23.2391 117.567 23.575C119.217 23.865 120.816 23.7057 122.365 23.0972C123.054 23.8279 123.587 24.6775 123.965 25.6458C120.863 27.5434 117.664 27.7027 114.367 26.1237C112.706 24.8855 111.746 23.2395 111.488 21.1857C111.488 20.1237 111.488 19.0618 111.488 17.9998C112.512 13.6123 115.284 11.6478 119.806 12.106C123.066 13.2836 124.666 15.6199 124.605 19.1149C124.603 19.7624 124.496 20.3465 124.285 20.8671Z"
                  fill="#040404"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M129.725 18.6368C129.407 21.3396 129.301 24.1007 129.405 26.9199C128.019 26.9199 126.632 26.9199 125.246 26.9199C125.246 22.035 125.246 17.15 125.246 12.2651C126.419 12.2651 127.592 12.2651 128.765 12.2651C128.904 13.1143 129.064 13.9639 129.245 14.8137C130.471 12.5839 132.337 11.6812 134.843 12.1058C135.01 12.2846 135.117 12.497 135.163 12.743C134.868 13.867 134.548 14.9821 134.204 16.0881C131.867 15.449 130.375 16.2985 129.725 18.6368Z"
                  fill="#020202"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M110.85 12.2656C110.85 17.1506 110.85 22.0355 110.85 26.9205C109.357 26.9205 107.864 26.9205 106.371 26.9205C106.371 22.0355 106.371 17.1506 106.371 12.2656C107.864 12.2656 109.357 12.2656 110.85 12.2656Z"
                  fill="black"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M53.5862 12.5842C55.0831 12.5314 56.5758 12.5845 58.065 12.7435C58.6722 13.0301 59.2051 13.4018 59.6645 13.8585C59.5046 14.1771 59.3446 14.4957 59.1847 14.8143C57.7255 13.6601 56.0726 13.2884 54.226 13.6993C52.6534 14.4923 52.2269 15.7135 52.9464 17.363C54.8518 18.5816 56.8781 19.5904 59.0247 20.3895C60.8306 21.8884 61.0971 23.6406 59.8245 25.6462C59.4361 26.1396 58.9562 26.5113 58.3849 26.7612C55.5111 27.7543 52.9518 27.2764 50.707 25.3276C50.8136 25.009 51.0269 24.7966 51.3469 24.6904C53.5318 26.4226 55.8777 26.6881 58.3849 25.4869C59.8062 23.7828 59.5929 22.2961 57.7451 21.0267C55.9641 20.4585 54.2577 19.7152 52.6265 18.7966C50.7102 16.3825 51.0301 14.3117 53.5862 12.5842Z"
                  fill="#101010"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M69.261 12.5834C76.5352 12.4562 79.2544 15.9607 77.4188 23.0967C75.4494 27.0429 72.4102 28.158 68.3013 26.4418C64.6713 22.8398 64.1914 18.8575 66.8617 14.4949C67.6544 13.8133 68.4542 13.1762 69.261 12.5834Z"
                  fill="#0E0E0E"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M79.4961 26.9205C79.4961 22.2479 79.4961 17.5754 79.4961 12.9028C79.816 12.9028 80.1359 12.9028 80.4558 12.9028C80.3615 13.615 80.4683 14.2521 80.7757 14.8143C82.1891 13.5 83.8418 12.6504 85.7343 12.2656C88.0396 12.3984 89.4258 13.5666 89.8932 15.77C90.0531 19.4853 90.1066 23.2022 90.0531 26.9205C89.6267 26.9205 89.1999 26.9205 88.7735 26.9205C88.8269 23.2022 88.7735 19.4853 88.6135 15.77C88.1669 14.1657 87.1007 13.4223 85.4144 13.54C83.6101 13.8025 82.1171 14.6521 80.9357 16.0886C80.7757 19.6977 80.7223 23.3083 80.7757 26.9205C80.3493 26.9205 79.9225 26.9205 79.4961 26.9205Z"
                  fill="#0F0F0F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.268 12.5842C45.7408 12.5007 46.1673 12.6069 46.5476 12.9028C44.775 17.5622 43.0689 22.2348 41.4291 26.9205C40.7998 26.9967 40.2131 26.8906 39.6696 26.6019C37.9817 22.0364 36.3287 17.47 34.7109 12.9028C35.1374 12.9028 35.5641 12.9028 35.9906 12.9028C37.4494 17.1876 38.9955 21.4353 40.6293 25.6461C42.2723 21.3208 43.8188 16.9668 45.268 12.5842Z"
                  fill="#131313"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M48.1484 12.9023C48.5749 12.9023 49.0016 12.9023 49.4281 12.9023C49.4281 17.5749 49.4281 22.2475 49.4281 26.92C49.0016 26.92 48.5749 26.92 48.1484 26.92C48.1484 22.2475 48.1484 17.5749 48.1484 12.9023Z"
                  fill="#050505"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M62.2227 12.9023C62.6491 12.9023 63.0759 12.9023 63.5023 12.9023C63.5023 17.5749 63.5023 22.2475 63.5023 26.92C63.0759 26.92 62.6491 26.92 62.2227 26.92C62.2227 22.2475 62.2227 17.5749 62.2227 12.9023Z"
                  fill="#050505"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M80.7755 14.8141C80.4681 14.2519 80.3612 13.6147 80.4556 12.9026C80.1357 12.9026 79.8158 12.9026 79.4959 12.9026C79.4959 17.5751 79.4959 22.2477 79.4959 26.9203C79.1772 22.1976 79.0707 17.4188 79.1759 12.584C79.7092 12.584 80.2422 12.584 80.7755 12.584C80.7755 13.3273 80.7755 14.0707 80.7755 14.8141Z"
                  fill="#CECECE"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.9562 12.903C17.8662 12.797 22.7714 12.9032 27.6721 13.2216C28.0167 13.9318 28.2299 14.6752 28.3119 15.4517C22.9801 15.4517 17.6483 15.4517 12.3164 15.4517C12.4645 14.5848 12.6777 13.7352 12.9562 12.903Z"
                  fill="#0C8B97"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M69.8987 13.5397C73.3083 13.0325 75.4942 14.413 76.4569 17.6813C77.3168 21.4514 76.0902 24.2655 72.7779 26.1238C69.375 26.4157 67.2956 24.8758 66.5396 21.5043C65.9132 18.083 67.0329 15.4282 69.8987 13.5397Z"
                  fill="#DBE4FF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M97.7328 15.7701C99.3129 15.4043 100.433 15.9353 101.092 17.363C101.305 18.7435 101.305 20.1241 101.092 21.5046C100.123 23.8627 98.6302 24.2875 96.6132 22.7789C96.3998 20.7613 96.3998 18.7435 96.6132 16.7258C96.9795 16.3638 97.3528 16.0452 97.7328 15.7701Z"
                  fill="#DBE4FF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M117.248 15.4516C119.436 14.9954 120.609 15.8449 120.767 18.0002C119.061 18.0002 117.355 18.0002 115.648 18.0002C115.702 16.828 116.235 15.9785 117.248 15.4516Z"
                  fill="#DBE4FF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.8723 21.1854C23.8855 21.2907 16.9542 21.1845 10.0781 20.8668C10.1057 20.1732 10.319 19.5361 10.7179 18.9553C17.1162 18.5305 23.5144 18.5305 29.9126 18.9553C30.2971 19.6818 30.617 20.4252 30.8723 21.1854Z"
                  fill="#0E8A96"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.15935 24.3718C16.2666 24.2657 24.3709 24.3719 32.4726 24.6904C32.8171 25.4006 33.0305 26.144 33.1124 26.9205C24.5814 26.9205 16.0505 26.9205 7.51953 26.9205C7.73282 26.0709 7.94607 25.2214 8.15935 24.3718Z"
                  fill="#048995"
                />
              </svg> */}
              {/* <svg
                className="w-[8rem] h-[8rem] md:w-[15rem] md:h-[10rem]"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                imageRendering="optimizeQuality"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
              >
                <path
                  fill="#a4afba"
                  d="M299.5-.5h25c6.813.991 13.813 1.325 21 1a602.828 602.828 0 01-11 94 2404.07 2404.07 0 00-12 47C282.692 263.143 203.025 346.977 83.5 393a399.985 399.985 0 01-83 21.5v-13a320.317 320.317 0 0115-5c132.452-30.455 220.119-110.121 263-239a375.13 375.13 0 0013-45.5c-.07-.765-.403-1.265-1-1.5 8.116-36.61 11.116-73.61 9-111z"
                  opacity="0.968"
                ></path>
                <path
                  fill="#00508b"
                  d="M961.5 12.5v3a663.027 663.027 0 00-10 50c-138.073-3.454-275.406 5.046-412 25.5a1391.687 1391.687 0 00-217 50.5 2404.07 2404.07 0 0112-47c88.473-30.063 179.14-50.897 272-62.5a2589.078 2589.078 0 01355-19.5z"
                  opacity="0.982"
                ></path>
                <path
                  fill="#00508b"
                  d="M290.5 110.5c.597.235.93.735 1 1.5a375.13 375.13 0 01-13 45.5c-66.026 23.643-125.359 58.143-178 103.5-32.225 29.218-57.059 63.718-74.5 103.5a199.602 199.602 0 00-10.5 32 320.317 320.317 0 00-15 5c5.824-42.316 21.323-80.65 46.5-115 33.443-44.113 73.277-81.28 119.5-111.5a841.109 841.109 0 01124-64.5z"
                  opacity="0.963"
                ></path>
                <path
                  fill="#005087"
                  d="M549.5 162.5h29l-26 137c-9.69.332-19.356-.002-29-1a8148.579 8148.579 0 0026-136z"
                  opacity="0.98"
                ></path>
                <path
                  fill="#005087"
                  d="M473.5 168.5c15.818-1.006 30.485 2.494 44 10.5 10.236 10.096 13.736 22.263 10.5 36.5-6.118 21.352-20.285 33.852-42.5 37.5a83.713 83.713 0 01-27 .5 2778.333 2778.333 0 00-8 46c-9.672.166-19.339 0-29-.5A10602.587 10602.587 0 00446 172.5c9.162-2.103 18.329-3.436 27.5-4zm5 23c20.186 1.042 26.02 10.708 17.5 29-8.95 9.036-19.783 12.37-32.5 10a679.473 679.473 0 016.5-36 24.295 24.295 0 018.5-3zM617.5 203.5c15.48-3.114 28.646.886 39.5 12 8.895 18.587 4.395 32.753-13.5 42.5-13.954 4.684-28.288 6.517-43 5.5 1.412 8.579 6.412 13.746 15 15.5 10.87 1.188 21.203-.645 31-5.5a6.802 6.802 0 011.5 3c.867 5.993 1.2 11.993 1 18-14.818 6.572-30.318 8.739-46.5 6.5-15.527-3.527-25.027-13.027-28.5-28.5-3.498-24.143 4.002-43.976 22.5-59.5 6.606-4.308 13.606-7.474 21-9.5zm2 21c4.137-.354 8.137.146 12 1.5 3.424 3.265 3.757 6.765 1 10.5-2.535 2.35-5.535 3.85-9 4.5-6.653 1.474-13.319 1.641-20 .5 2.679-8.341 8.012-14.008 16-17zM710.5 203.5c17.739-3.2 32.572 1.8 44.5 15 10.805 24.172 7.805 46.505-9 67-19.209 17.234-40.709 20.734-64.5 10.5-11.073-8.312-16.74-19.479-17-33.5 1.337-30.963 16.671-50.63 46-59zm2 22c9.078-1.716 15.245 1.618 18.5 10 2.489 16.199-2.011 30.033-13.5 41.5-14.702 5.481-22.368.315-23-15.5.143-15.19 6.143-27.19 18-36z"
                  opacity="0.983"
                ></path>
                <path
                  fill="#005087"
                  d="M825.5 203.5h13a192.51 192.51 0 01-5 27c-14.546-.287-24.38 6.379-29.5 20a560.045 560.045 0 00-10.5 48 105.651 105.651 0 01-29 0 869.339 869.339 0 0015.5-91c4.077-1.298 8.411-1.964 13-2l12.5.5a87.187 87.187 0 00-1.5 16.5c5.205-8.714 12.371-15.047 21.5-19z"
                  opacity="0.982"
                ></path>
                <path
                  fill="#005087"
                  d="M888.5 203.5c9.339-.166 18.673 0 28 .5a77.929 77.929 0 0117 4 508.917 508.917 0 00-14 91.5h-26c.355-5.349.522-10.682.5-16-11.477 17.491-26.977 22.325-46.5 14.5-6.55-4.924-10.717-11.424-12.5-19.5-3.154-40.3 14.679-65.3 53.5-75zm4 21l8 1a154.022 154.022 0 01-8.5 38c-3.223 7.897-8.89 12.897-17 15-8.031-1.554-11.864-6.554-11.5-15 1.007-14.246 7.007-25.746 18-34.5 3.553-2.121 7.22-3.621 11-4.5z"
                  opacity="0.985"
                ></path>
                <path
                  fill="#005087"
                  d="M804.5 337.5c10.428 1.186 13.261 6.52 8.5 16-8.175 4.999-13.675 2.999-16.5-6 .883-4.872 3.55-8.205 8-10z"
                  opacity="0.944"
                ></path>
                <path
                  fill="#005087"
                  d="M482.5 338.5h16a1916.243 1916.243 0 00-4.5 28c7.149-6.083 14.982-7.249 23.5-3.5 2.31 1.637 3.81 3.804 4.5 6.5a49.226 49.226 0 010 14 840.778 840.778 0 00-5.5 31 145.053 145.053 0 01-17-.5 310.188 310.188 0 006-32.5c.156-6.687-2.844-8.52-9-5.5a18.778 18.778 0 00-4.5 5.5 265.39 265.39 0 00-7.5 33h-16a2538.111 2538.111 0 0114-76zM657.5 338.5h16a7619.473 7619.473 0 00-14 76 73.133 73.133 0 01-17-1 7476.052 7476.052 0 0015-75z"
                  opacity="0.97"
                ></path>
                <path
                  fill="#005087"
                  d="M332.5 342.5c18.345-.333 36.679 0 55 1a541.784 541.784 0 00-3 12.5c-6.325.5-12.658.666-19 .5-3.39 18.674-6.89 37.34-10.5 56-5.197 1.92-10.697 2.587-16.5 2a1155.333 1155.333 0 0111-57.5c-6.325-.5-12.658-.666-19-.5a254.587 254.587 0 002-14z"
                  opacity="0.977"
                ></path>
                <path
                  fill="#005087"
                  d="M399.5 360.5c16.406-1.426 23.906 5.907 22.5 22-1.902 4.9-5.402 8.067-10.5 9.5a64.08 64.08 0 01-22 2.5c1.001 6.028 4.668 9.028 11 9a40.262 40.262 0 0014-4 173.63 173.63 0 012 10.5 3.943 3.943 0 01-1 2c-9.038 3.338-18.371 4.338-28 3-5.949-1.892-10.116-5.725-12.5-11.5-4.713-21.927 3.454-36.261 24.5-43zm1 12c2.599.049 4.766 1.049 6.5 3 .711 1.867.211 3.367-1.5 4.5-4.791 2.199-9.791 2.866-15 2 1.942-4.775 5.275-7.942 10-9.5zM454.5 360.5c3.35-.165 6.683.002 10 .5 2.508.115 4.508 1.115 6 3a59.76 59.76 0 01-4.5 11.5c-10.878-3.397-18.545.27-23 11-1.559 14.593 4.607 19.26 18.5 14 1.333 4.333 1.333 8.667 0 13-7.19 2.011-14.523 2.511-22 1.5-5.865-1.511-10.032-5.011-12.5-10.5-5.211-23.909 3.955-38.576 27.5-44z"
                  opacity="0.967"
                ></path>
                <path
                  fill="#005087"
                  d="M563.5 360.5c11.164-1.096 17.164 3.904 18 15a476.422 476.422 0 01-7 38 64.873 64.873 0 01-16 1 906.044 906.044 0 016-34c-1.628-6.626-5.294-7.792-11-3.5a28.415 28.415 0 00-4.5 7.5 372.276 372.276 0 00-6 29 70.907 70.907 0 01-16.5 1 1227.312 1227.312 0 009-53h15a45.296 45.296 0 00-.5 7c3.897-3.859 8.397-6.525 13.5-8z"
                  opacity="0.966"
                ></path>
                <path
                  fill="#005087"
                  d="M613.5 360.5c19.813-.354 28.647 9.313 26.5 29-5.023 20.105-17.857 28.605-38.5 25.5-10.541-3.235-15.541-10.402-15-21.5.667-17.986 9.667-28.986 27-33zm0 13c7.216-.282 10.55 3.218 10 10.5-.352 7.066-3.019 13.066-8 18-8.223 2.787-12.223-.213-12-9-.154-8.42 3.179-14.92 10-19.5z"
                  opacity="0.969"
                ></path>
                <path
                  fill="#005087"
                  d="M697.5 360.5c22.123-.375 30.957 10.292 26.5 32-6.251 18.386-19.084 25.886-38.5 22.5-12.43-6.036-16.93-15.869-13.5-29.5 3.781-13.282 12.281-21.615 25.5-25zm0 13c7.436-.9 11.103 2.434 11 10-.674 6.528-3.008 12.361-7 17.5-8.009 4.16-12.676 1.66-14-7.5.732-8.13 4.065-14.797 10-20z"
                  opacity="0.971"
                ></path>
                <path
                  fill="#005087"
                  d="M759.5 360.5c8.745-.432 17.079 1.068 25 4.5a1311.839 1311.839 0 00-8.5 44.5c-2.405 18.579-12.905 27.579-31.5 27a63.693 63.693 0 01-17-2 60.204 60.204 0 013-12c2.337-1.012 4.67-.845 7 .5 5.926 1.11 11.592.444 17-2 3.475-3.096 4.975-6.93 4.5-11.5-7.453 5.993-15.287 6.493-23.5 1.5-4.673-4.515-6.673-10.015-6-16.5 1.191-19.187 11.191-30.52 30-34zm-1 13c2.357-.163 4.69.003 7 .5.184 8.918-2.149 17.251-7 25-4.451 3.64-8.285 3.14-11.5-1.5-1.746-10.837 2.087-18.837 11.5-24zM839.5 360.5c18.622-1.224 25.789 7.109 21.5 25-4.664 5.117-10.497 7.95-17.5 8.5a98.432 98.432 0 01-14 .5c.049 2.599 1.049 4.766 3 6.5 7.47 2.93 14.803 2.43 22-1.5a38.496 38.496 0 012 13c-9.129 2.786-18.462 3.619-28 2.5-12.128-4.643-16.961-13.476-14.5-26.5 2.486-14.979 10.986-24.312 25.5-28zm2 12c5.36.783 6.694 3.283 4 7.5-4.469 2.441-9.135 2.941-14 1.5 2.167-4.511 5.5-7.511 10-9z"
                  opacity="0.968"
                ></path>
                <path
                  fill="#005087"
                  d="M886.5 360.5a72.273 72.273 0 0116 1.5c1.41.368 2.41 1.201 3 2.5a73.377 73.377 0 01-4.5 11c-4.792-2.336-9.626-2.836-14.5-1.5-2.667 2-2.667 4 0 6 6.713 2.393 11.88 6.559 15.5 12.5 2.347 12.14-2.486 19.64-14.5 22.5-8.179 1.102-16.179.435-24-2-1.054-1.388-1.22-2.888-.5-4.5.585-3.12 1.919-5.787 4-8a40.352 40.352 0 0012 3c4.76.574 6.76-1.426 6-6a163.431 163.431 0 01-14-10c-4.297-8.662-2.797-16.162 4.5-22.5a78.147 78.147 0 0111-4.5zM795.5 361.5h16a2283.822 2283.822 0 01-9.5 51c-5.197 1.92-10.697 2.587-16.5 2a1469.158 1469.158 0 0110-53z"
                  opacity="0.961"
                ></path>
                <path
                  fill="#b2b7bb"
                  d="M-.5 625.5v-125c2.375-9.544 8.375-15.711 18-18.5 302.333-.667 604.667-.667 907 0 10.053 2.72 15.887 9.22 17.5 19.5.667 41 .667 82 0 123-1.544 9.876-7.044 16.376-16.5 19.5-303 .667-606 .667-909 0-9.028-3.19-14.694-9.356-17-18.5z"
                  opacity="0.996"
                ></path>
                <path
                  fill="#fefefe"
                  d="M186.5 511.5c7.341-.166 14.674 0 22 .5a53.91 53.91 0 0114 3.5l1 1.5a78.896 78.896 0 00-3 13.5c-14.607-5.81-28.94-5.31-43 1.5-14.891 11.349-19.724 26.183-14.5 44.5 3.829 12.173 11.996 19.673 24.5 22.5 11.197 1.203 22.197.37 33-2.5a81.539 81.539 0 002 12.5c-.255 1.326-.922 2.326-2 3-16.382 4.564-32.716 4.564-49 0-20.943-8.798-31.276-24.632-31-47.5 1.795-29.459 17.128-47.126 46-53z"
                ></path>
                <path
                  fill="#fdfdfe"
                  d="M275.5 511.5c7.674-.166 15.341 0 23 .5 5.429.2 10.429 1.7 15 4.5a54.839 54.839 0 00-4 14c-14.568-5.804-28.901-5.304-43 1.5-15.267 12.215-19.767 27.715-13.5 46.5 6.835 15.338 18.668 22.338 35.5 21 7.234.41 14.234-.59 21-3a105.744 105.744 0 003 14 4.457 4.457 0 01-2 1.5c-16.694 4.589-33.361 4.589-50 0-20.478-9.104-30.812-24.938-31-47.5 1.804-29.468 17.138-47.134 46-53z"
                ></path>
                <path
                  fill="#fefefe"
                  d="M590.5 613.5h-19v-99c17.344-2.772 34.678-2.606 52 .5 18.681 7.692 23.848 20.859 15.5 39.5-4.013 5.34-9.179 9.174-15.5 11.5 6.223 3.223 10.723 8.057 13.5 14.5a322.175 322.175 0 009.5 33 67.229 67.229 0 01-20 .5 441.208 441.208 0 01-9.5-31.5c-2.368-4.935-6.201-8.102-11.5-9.5a112.97 112.97 0 00-15-.5v41z"
                ></path>
                <path
                  fill="#fdfdfd"
                  d="M74.5 512.5h26a9134.494 9134.494 0 0134 102c-7.032.33-14.032-.003-21-1a341.59 341.59 0 01-9-28h-35a927.738 927.738 0 01-8 29h-21a9139.29 9139.29 0 0134-102z"
                ></path>
                <path
                  fill="#fdfefe"
                  d="M329.5 512.5h62v16h-42c-.993 8.484-1.326 17.151-1 26h40v15h-40v29h45v15h-64v-101z"
                ></path>
                <path
                  fill="#e9ebec"
                  d="M430.5 513.5h-19v100h63c-21.16.997-42.493 1.331-64 1v-102c6.854-.324 13.521.009 20 1z"
                ></path>
                <path
                  fill="#fefffe"
                  d="M430.5 513.5v85h44v15h-63v-100h19z"
                ></path>
                <path
                  fill="#fefefe"
                  d="M489.5 512.5h62v16h-42c-.993 8.484-1.326 17.151-1 26h41v15h-41v29a507.055 507.055 0 0045 1v14h-64v-101z"
                ></path>
                <path
                  fill="#fdfdfd"
                  d="M688.5 512.5c8.585-.317 17.085.017 25.5 1a3940.098 3940.098 0 0134.5 101c-7.032.33-14.032-.003-21-1a982.328 982.328 0 00-10-27.5 577.896 577.896 0 00-34-.5 1335.789 1335.789 0 01-8.5 28 108.41 108.41 0 01-20.5 1 9141.347 9141.347 0 0134-102z"
                ></path>
                <path
                  fill="#fefefe"
                  d="M740.5 512.5h81v16h-31v86h-19v-86h-31v-16z"
                ></path>
                <path
                  fill="#e9ebec"
                  d="M896.5 513.5h-62v100h64c-21.494.997-43.16 1.331-65 1v-102a992.742 992.742 0 0163 1z"
                ></path>
                <path
                  fill="#fefefe"
                  d="M896.5 513.5v15h-42c-.993 8.484-1.326 17.151-1 26h40v15h-40v29h45v15h-64v-100h62z"
                ></path>
                <path
                  fill="#e9ebec"
                  d="M571.5 514.5v99h19c-6.479.991-13.146 1.324-20 1-.332-33.504.002-66.838 1-100z"
                ></path>
                <path
                  fill="#b3b8bc"
                  d="M590.5 526.5a98.746 98.746 0 0123 1.5c9.294 5.215 11.794 12.715 7.5 22.5-2.928 3.881-6.761 6.381-11.5 7.5a99.676 99.676 0 01-19 1.5v-33z"
                ></path>
                <path
                  fill="#b4b9bd"
                  d="M86.5 528.5a1550.306 1550.306 0 0014 42c-9 1.333-18 1.333-27 0a381.929 381.929 0 0013-42zM699.5 528.5c4.926 13.76 9.926 27.76 15 42a183.078 183.078 0 01-27 1 236.65 236.65 0 018.5-26 308.29 308.29 0 003.5-17z"
                ></path>
                <path
                  fill="#d1d5d8"
                  d="M349.5 528.5v25h40c.321 5.526-.012 10.86-1 16v-15h-40c-.326-8.849.007-17.516 1-26z"
                ></path>
                <path
                  fill="#d1d4d7"
                  d="M509.5 528.5v25c13.51-.329 26.844.004 40 1h-41c-.326-8.849.007-17.516 1-26z"
                ></path>
                <path
                  fill="#d1d5d8"
                  d="M854.5 528.5v25h40c.321 5.526-.012 10.86-1 16v-15h-40c-.326-8.849.007-17.516 1-26z"
                ></path>
                <path
                  fill="#e2e4e6"
                  d="M329.5 512.5v101h64c-21.494.997-43.16 1.331-65 1-.332-34.171.002-68.171 1-102z"
                ></path>
                <path
                  fill="#e1e4e6"
                  d="M489.5 512.5v101h64v-14a507.055 507.055 0 01-45-1h46v16h-66c-.332-34.171.002-68.171 1-102z"
                ></path>
                <path
                  fill="#004f8b"
                  d="M335.5 751.5h-18a475.573 475.573 0 00-8-3c-.51-4.332-.01-8.666 1.5-13a3.647 3.647 0 011.5-1c6.716 3.647 13.716 4.48 21 2.5 5.333-4 5.333-8 0-12-7.584-3.037-14.417-7.204-20.5-12.5-7.532-20.231-.366-31.231 21.5-33a71.279 71.279 0 0115 3c.48 4.78-.353 9.447-2.5 14-6.922-3.85-13.755-3.85-20.5 0-1.02 1.932-1.187 3.932-.5 6a101.2 101.2 0 0018.5 10.5c9.988 8.913 11.488 19.079 4.5 30.5-3.887 3.854-8.387 6.521-13.5 8z"
                  opacity="0.934"
                ></path>
                <path
                  fill="#004f8b"
                  d="M425.5 751.5h-17c-11.221-4.068-18.055-12.068-20.5-24-3.177-14.795-.177-28.128 9-40 12.237-9.507 25.07-10.34 38.5-2.5 10.622 11.059 14.455 24.226 11.5 39.5-1.851 13.34-9.018 22.34-21.5 27zm-9-59c8.1 1.606 12.6 6.606 13.5 15 1.584 9.489.25 18.489-4 27-10.452 6.953-17.452 4.286-21-8-2.54-9.724-1.54-19.058 3-28 2.322-2.895 5.155-4.895 8.5-6z"
                  opacity="0.94"
                ></path>
                <path
                  fill="#004f8b"
                  d="M36.5 680.5h40v14h-24v14h22v13h-22v16h25v13h-41v-70z"
                  opacity="0.938"
                ></path>
                <path
                  fill="#004f8b"
                  d="M81.5 680.5c6.01-.166 12.01.001 18 .5a675.274 675.274 0 019.5 22.5 675.274 675.274 0 019.5-22.5 145.053 145.053 0 0117-.5 841.471 841.471 0 01-17 35l18 34a54.557 54.557 0 01-18 .5 693.699 693.699 0 01-10.5-22.5 318.958 318.958 0 00-8.5 23 81.884 81.884 0 01-18-1 1650.785 1650.785 0 0017-34.5 551.247 551.247 0 01-17-34.5z"
                  opacity="0.929"
                ></path>
                <path
                  fill="#004f8b"
                  d="M138.5 680.5h50v14h-17v56h-16v-56h-17v-14z"
                  opacity="0.937"
                ></path>
                <path
                  fill="#004f8b"
                  d="M195.5 680.5h40v14h-24v14h22v13h-22v16h25v13h-41v-70z"
                  opacity="0.944"
                ></path>
                <path
                  fill="#004f8b"
                  d="M245.5 680.5a81.883 81.883 0 0118 1 447.236 447.236 0 0121.5 44 2036.842 2036.842 0 01-.5-45h15v70a73.133 73.133 0 01-17-1 489.101 489.101 0 01-22.5-46c.436 15.623.603 31.289.5 47h-15v-70z"
                  opacity="0.919"
                ></path>
                <path
                  fill="#004f8b"
                  d="M362.5 680.5h16v70h-16v-70z"
                  opacity="0.943"
                ></path>
                <path
                  fill="#004f8b"
                  d="M456.5 680.5a79.547 79.547 0 0117.5 1 616.694 616.694 0 0121 42c.5-14.329.667-28.663.5-43h14v70a70.907 70.907 0 01-16.5-1 467.873 467.873 0 01-22-45c-.5 15.33-.667 30.663-.5 46h-14v-70z"
                  opacity="0.955"
                ></path>
                <path
                  fill="#004f8b"
                  d="M543.5 680.5a108.41 108.41 0 0120.5 1 1060.142 1060.142 0 0111 44c3.844-14.7 8.01-29.367 12.5-44 6.634-.997 13.3-1.33 20-1a1505.46 1505.46 0 004 69.5c-5.323.499-10.656.666-16 .5a7423.037 7423.037 0 00-1.5-49 820.705 820.705 0 01-13.5 47 43.117 43.117 0 01-13 1 532.52 532.52 0 00-11.5-44 3566.549 3566.549 0 01-2 43c-4.518 1.907-9.352 2.574-14.5 2a6173.816 6173.816 0 004-70z"
                  opacity="0.938"
                ></path>
                <path
                  fill="#004f8b"
                  d="M621.5 680.5h40v14h-24v14h22v13h-22v16h25v13h-41v-70z"
                  opacity="0.944"
                ></path>
                <path
                  fill="#004f8b"
                  d="M674.5 680.5h20a1159.567 1159.567 0 0111.5 46 745.961 745.961 0 0112-45 98.26 98.26 0 0119.5-1 2214.66 2214.66 0 014 70h-15a982.655 982.655 0 00-2.5-46 3508.053 3508.053 0 01-12.5 45 43.117 43.117 0 01-13-1 579.64 579.64 0 01-11.5-46l-2.5 48h-14a2875.183 2875.183 0 014-70z"
                  opacity="0.939"
                ></path>
                <path
                  fill="#004f8b"
                  d="M778.5 751.5h-24c-1.974-23.104-2.641-46.437-2-70a167.732 167.732 0 0130-.5c14.745 3.656 19.245 12.49 13.5 26.5-1.938 2.606-4.438 4.439-7.5 5.5 10.613 4.394 14.446 12.227 11.5 23.5-.964 3.968-3.131 7.135-6.5 9.5-4.799 2.762-9.799 4.596-15 5.5zm-11-59c3.35-.165 6.683.002 10 .5 6.259 4.167 6.592 8.833 1 14a27.866 27.866 0 01-11 1.5v-16zm0 28c4.949-.541 9.616.292 14 2.5 4.188 5.597 3.521 10.597-2 15a31.818 31.818 0 01-12 1.5v-19z"
                  opacity="0.959"
                ></path>
                <path
                  fill="#004f8b"
                  d="M810.5 680.5h40v14h-24v14h22v13h-22v16h25v13h-41v-70z"
                  opacity="0.944"
                ></path>
                <path
                  fill="#004f8b"
                  d="M865.5 680.5c9.733-.391 19.4.109 29 1.5 6.425 1.427 10.592 5.26 12.5 11.5 3.062 10.694-.105 18.86-9.5 24.5 2.902 2.24 5.069 5.073 6.5 8.5a586.28 586.28 0 006.5 23c-5.667 1.333-11.333 1.333-17 0a584.85 584.85 0 00-6.5-23c-3.041-2.429-6.541-3.429-10.5-3v27h-16v-69c1.915.284 3.581-.049 5-1zm11 12c4.621-.507 8.954.326 13 2.5 3.892 5.475 3.226 10.475-2 15-3.526 1.337-7.192 1.837-11 1.5v-19z"
                  opacity="0.94"
                ></path>
              </svg> */}
              <a
                href="https://en.ids-imaging.com/visionpier-offer/nocode-platform-1022.html"
                target="_blank"
                className="no-underline	"
              >
                <img
                  src={vision}
                  alt=""
                  className="self-center h-[5.5rem] w-full  md:h-[8rem] md:w-[14rem]"
                />
              </a>

              <img
                src={PLAccLogo}
                alt=""
                className="self-center h-[5rem] w-full md:h-[6.5rem] md:w-[12rem] cursor-pointer"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PartOfLogos;
