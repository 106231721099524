import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Affix,
  ColorScheme,
  ColorSchemeProvider,
  Paper,
  Transition,
  rem,
} from "@mantine/core";
import { MantineProvider, Text, Button } from "@mantine/core";
import { HeroTitle } from "./pages/hero/Hero";
import { useHotkeys } from "@mantine/hooks";
import { useLocalStorage } from "@mantine/hooks";
import ModeButton from "./pages/ModeButton";
import { Loader } from "@mantine/core";
import SupportedBy from "./pages/supported-by/SupportedBy";
import AboutTuba from "./pages/about-tuba/AboutTuba";
import { HeaderNavigation } from "./pages/navigation/Header";
import { Features } from "./pages/features/Features";
import DeploymentOptions from "./pages/deployment-options/DeploymentOptions";
import Team from "./pages/team/TeamCards";
import PriceCards from "./pages/pricing/PriceCards";
import { FAQ } from "./pages/faq/FAQ";
import { Contact } from "./pages/contact/Contact";
import Footer from "./pages/footer/Footer";
import Contact2 from "./pages/contact/Contact2";
import { Features2 } from "./pages/features/Features2";
import { CookiesBanner } from "./pages/cookie-banner/CookiesBanner";
import posthog from "posthog-js"; // new
import LogosSection from "./pages/logos-section/logos";
import NvidiaValidated from "./pages/nvidia-validated/nvidia-validated";
import PartOfLogos from "./pages/part-of-logos/PartOfLogos";
import { IconArrowUp } from "@tabler/icons-react";
import { useWindowScroll } from "@mantine/hooks";
import TransitionInitial from "./pages/initial-transition/Transition";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import ReactGA from "react-ga";

// Setup Google Analytics Inside Project
const TRACKING_ID = "UA-112156148-3"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props: any) {
  const [scroll, scrollTo] = useWindowScroll();

  // state to make a preloader screen for 2 seconds then load the website
  const [isSticky, setIsSticky] = useState(false);
  const appRef = useRef<any>();

  useEffect(() => {
    // if the viewport is intersecting with the supportedBy, then make the HeaderNavigation sticky
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        console.log("entry", entry);

        if (entry.isIntersecting === false) {
          setIsSticky(true);
        }
        if (entry.isIntersecting === true) {
          setIsSticky(false);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-80px",
      }
    );
    observer.observe(appRef.current);

    console.log("SupportedByRef from app.tsx", appRef.current);
  }, []);

  console.log("is Sticky", isSticky);

  const content = {
    animate: {
      transition: { staggerChildren: 0.1, delayChildren: 3.8 },
    },
  };

  return (
    <>
      <AnimatePresence>
        <MantineProvider
          theme={{
            colorScheme: "light",
            loader: "dots",
            fontSizes: {
              xs: "16px",
              sm: "18px",
              md: "20px",
              lg: "24px",
              xl: "28px",
              "2xl": "32px",
              "3xl": "38px",
            },
            colors: {
              indigo: [
                "#edf2ff",
                "#bac8ff",
                "#91a7ff",
                "#748ffc",
                "#5c7cfa",
                "#4c6ef5",
                "#4263eb",
                "#3b5bdb",
                "#364fc7",
                "#302ECB",
              ],
            },
            breakpoints: {
              xs: "30em",
              sm: "48em",
              md: "64em",
              lg: "74em",
              xl: "90em",
            },
            components: {
              Button: {
                styles: (theme) => ({
                  root: {
                    backgroundColor: theme.colors.indigo[9],
                  },
                }),
              },
            },
          }}
        >
          <Paper className="w-screen ">
            <TransitionInitial />
            <motion.div initial="initial" animate="animate" variants={content}>
              <HeaderNavigation
                className={
                  isSticky
                    ? "sticky top-0 z-50 bg-[#f7f7ff] shadow-md border border-slate-300 "
                    : ""
                }
                links={[
                  {
                    label: "Home",
                    to: "/",
                    offset: -200,
                    duration: 500,
                  },
                  {
                    label: "About",
                    to: "about",
                    offset: -80,
                    duration: 500,
                  },
                  {
                    label: "Solutions",
                    to: "solutions",
                    offset: -100,
                    duration: 500,
                  },
                  {
                    label: "Team",
                    to: "team",
                    offset: 10,
                    duration: 500,
                  },
                  {
                    label: "Packages",
                    to: "packages",
                    offset: -100,
                    duration: 500,
                  },
                  // {
                  //   label: "Contact",
                  //   to: "contact",
                  //   offset: -50,
                  //   duration: 500,
                  // },
                  {
                    label: "Login",
                    to: "login",
                    offset: -100,
                    duration: 500,
                  },
                  {
                    label: "Request Demo",
                    to: "request-demo",
                    offset: -100,
                    duration: 500,
                  },
                ]}
              />

              <HeroTitle ref={appRef} />
              <SupportedBy />
              {/* <LogosSection /> */}

              <AboutTuba />
              <PartOfLogos />
              <Features />
              <Features2 />
              <DeploymentOptions />
              <Team />
              <PriceCards />
              <FAQ />
              <NvidiaValidated />
              <Contact />
              {/* <Contact2 /> */}
              {posthog.has_opted_out_capturing() || // new
              posthog.has_opted_in_capturing() ? null : (
                <CookiesBanner />
              )}

              <Affix
                className="transition-all duration-300"
                position={{ bottom: rem(20), right: rem(20) }}
              >
                <Transition transition="slide-up" mounted={scroll.y > 0}>
                  {(transitionStyles) => (
                    <Button
                      // leftIcon={<IconArrowUp size="1rem" />}
                      style={transitionStyles}
                      onClick={() => scrollTo({ y: 0 })}
                      className="h-[3rem] bg-[#5452da] transition-all duration-300 ease-in-out hover:bg-[#20327a]"
                    >
                      <IconArrowUp size="1.4rem" />
                    </Button>
                  )}
                </Transition>
              </Affix>
              <Footer />
            </motion.div>
          </Paper>
        </MantineProvider>
      </AnimatePresence>
    </>
  );
}

export default App;
